 
import moment, * as moments from 'moment';
 
import { decode as atob, encode as btoa } from 'base-64';
 
import { MONTH_FULLNAME, MONTH_SHORTNAME } from 'src/globals/constants';

export type Time = {
  hours: number;
  minutes: number;
  seconds: number;
};

// Wait until meet condition or timeout
export function waitFor(conditionFunction, timeoutMSC = 400) {
  const poll = (resolve) => {
    if (conditionFunction()) resolve();
    else setTimeout((_) => poll(resolve), timeoutMSC);
  };

  return new Promise(poll);
}

export interface DateRanges {
  startDate: moment.Moment;
  endDate: moment.Moment;
}

export interface IMonthYear {
  month: number;
  year: number;
}

export class PxDateRange {
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  constructor(startDate: moment.Moment = moment(), endDate: moment.Moment = moment()) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  toString(locale: string = 'th') {
    if (!this.startDate) return '';

    let sDate = moment(this.startDate);
    let eDate = moment(this.endDate);
    let months = MONTH_SHORTNAME[locale];

    let value = '';
    let sYear = sDate.year(),
      sMonth = sDate.month(),
      sDay = sDate.date();
    let eYear = eDate.year(),
      eMonth = eDate.month(),
      eDay = eDate.date();

    if (sYear == eYear) {
      if (sMonth == eMonth) {
        if (sDay == eDay) value = sDay.toString() + ' ' + months[sMonth] + ' ' + sYear.toString();
        else value = sDay.toString() + '  -  ' + eDay.toString() + ' ' + months[sMonth] + ' ' + sYear.toString();
      } else {
        value = sDay.toString() + ' ' + months[sMonth] + '  -  ' + eDay.toString() + ' ' + months[eMonth] + ' ' + sYear.toString();
      }
    } else {
      value = sDay.toString() + ' ' + months[sMonth] + ' ' + sYear.toString() + '  -  ' + eDay.toString() + ' ' + months[eMonth] + ' ' + eYear.toString();
    }

    return value;
  }

  toStringMonthYear(start: IMonthYear, end: IMonthYear = start, locale: string = 'th') {
    let value = '';
    let array = MONTH_FULLNAME[locale];
    if (start.year == end.year) {
      if (start.month == end.month) value = array[start.month - 1] + ' ' + start.year;
      else {
        value = array[start.month - 1] + '  -  ' + array[end.month - 1] + ' ' + start.year;
      }
    } else {
      value = array[start.month - 1] + ' ' + start.year + '  -  ' + array[end.month - 1] + ' ' + end.year;
    }

    return value;
  }
}

export class PxUtils {
  /** example randomEnumValue(Colors) */
  static randomEnumValue(enumeration) {
    const values = Object.keys(enumeration);
    const enumKey = values[Math.floor(Math.random() * values.length)];
    return enumeration[enumKey];
  }

  static randomArrayValue(values) {
    return values[Math.floor(Math.random() * values.length)];
  }
  static encodeUrlWebApiParamData(inputString: string): string {
    return inputString.replace(/\//gi, '(slash)').replace(/\+/gi, '(plus)').replace(/=/gi, '(equal)');
  }

  // Wait until meet condition or timeout
  static waitFor(conditionFunction, timeoutMSC = 400) {
    const poll = (resolve) => {
      if (conditionFunction()) resolve();
      else setTimeout((_) => poll(resolve), timeoutMSC);
    };

    return new Promise(poll);
  }

  static cleanImageData(data) {
    return data
      .replace('data:image/png;base64,', '')
      .replace(/(\r\n|\n|\r)/gm, '')
      .replace('"', '')
      .replace(/^.+,/, '')
      .replace(/^"|"$/g, ``); // ถ้าไม่มีบรรทัดนี้จะพิมพ์ไม่ออกใน iOS หาตั้งนาน
  }

  /**
   * @param {*} base64
   * @returns อันนี้สุดยอดมาก หาขนาดของภาพ จาก base64 data ได้ด้วย เอาไว้คำนวณขนาด widht height ของ logo ได้
   */
  static getPngDimensions(base64) {
    let header = base64.slice(0, 50);
    let uint8 = Uint8Array.from(atob(header), (c: any) => c.charCodeAt(0));
    let dataView = new DataView(uint8.buffer, 0, 28);

    return {
      width: dataView.getInt32(16),
      height: dataView.getInt32(20),
    };
  }

  static canvasToDataURL(canvas):Promise<any> {
    return new Promise((resolve, reject) => {
      canvas
        .toDataURL()
        .then((data) => {
          const imageData = this.cleanImageData(data);
          resolve(imageData);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}

export class PxConvert {
  static toBoolean(oj): boolean {
    let a: any = 3;

    if (oj == null || oj == undefined) return false;
    else return <boolean>oj;
  }
  static toDateTime(oj) {
    if (oj == null || oj == undefined) return new Date(1900, 1);
    else return <Date>oj;
  }
  static toDecimal(oj) {
    if (oj == null || oj == undefined) return 0;
    else return <number>oj;
  }
  static toDouble(oj) {
    if (oj == null || oj == undefined) return 0;
    else return <number>oj;
  }
  static toInt32(oj) {
    if (oj == null || oj == undefined || oj == '') return 0;
    else return <number>oj;
  }
  static toInt64(oj) {
    if (oj == null || oj == undefined || oj == '') return 0;
    else return <number>oj;
  }
  static toByte(oj) {
    if (oj == null || oj == undefined) return 0;
    else return <Uint8Array>oj;
  }
  static toString(oj?) {
    if (oj == null || oj == undefined) return '';
    else return <string>oj;
  }

  static toStringComma(value: [any]): string {
    let str = '';
    for (let i = 0; i < value.length; i++) {
      str += (str != '' ? ',' : '') + value[i].toString();
    }
    return str;
  }

  static toKbString(value: number) {
    return (value / 1024).toString() + 'Kb.';
  }

  static getKeyByValue(object, value) {
    for (var prop in object) {
      if (object.hasOwnProperty(prop)) {
        if (object[prop] === value) return prop;
      }
    }
  }

  static arrayBufferToBase64(buffer: Uint8Array): string {
    let binary = '';
    let bytes = new Uint8Array(buffer);
    let len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary); // ..#.. มี Error [TypeError: window.btoa is not a function. (In 'window.btoa(binary)', 'window.btoa' is undefined)]
  }

  static dataUrlToBase64(dataURL) {
    let BASE64_MARKER = ';base64,';
    let base64Index = dataURL.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    let base64 = dataURL.substring(base64Index);
    return base64;
  }

  static base64ToHexString(base64) {
    var raw = atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for (let i = 0; i < rawLength; i++) {
      array[i] = raw.charCodeAt(i);
    }

    let hexString = this.byteArrayToHexString(array);
    return hexString;
  }

  static byteArrayToHexString(byteArray) {
    var s = '0x';
    byteArray.forEach(function (byte) {
      s += ('0' + (byte & 0xff).toString(16)).slice(-2);
    });
    return s;
  }
}

export class PxUniqueId {
  static nextUid = 1;
  //** return number */
  static generateUid(): number {
    PxUniqueId.nextUid += 1;
    return PxUniqueId.nextUid;
  }

  //** return 9f1cfa-0f92d2-8b2b40-4a55b1 */
  static generateUUid(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x1000000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }

  /*
   * generate groups of 4 random characters
   * @example getUniqueId(1) : 607f
   * @example getUniqueId(2) : 52a2-b48b
   * @example getUniqueId(4) : 95ca-361a-f8a1-1e73
   */
  static getUniqueId(parts: number): string {
    const stringArr = [];
    for (let i = 0; i < parts; i++) {
      // tslint:disable-next-line:no-bitwise
      const S4 = (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
      stringArr.push(S4);
    }
    return stringArr.join('-');
  }
}

// export class PxAge {
//   static getAge(birthDate): number {
//     let d = PxConvert.toDateTime(birthDate);
//     let now = new Date();
//     let years = now.getFullYear() - d.getFullYear();
//     if (now.getMonth() < d.getMonth() || (now.getMonth() == d.getMonth() && now.getDate() < d.getDate())) --years;

//     return years;
//   }

//   static isValid(age: number): boolean {
//     return age >= 0 && age <= 120;
//   }
// }

export class PxDiscount {
  //
  // ไปทำใน tranPayment ทีเดียว เพราะ PxDataTable ใส่ในนี้ไม่ได้
  //
  // static getDiscountTable(intputStr: string, sorted: boolean): PxDataTable {

  // let dt = new PxDataTable()
  // dt.columns.push(new PxDataColumn("DText", PxSqlDataType.varchar))
  // dt.columns.push(new PxDataColumn("DType", PxSqlDataType.varchar))
  // dt.columns.push(new PxDataColumn("DVal", PxSqlDataType.decimal))

  // // let discountList: string[] = getDiscountList(intputStr, sorted);
  // foreach(string d in discountList)
  // {
  //     DataRow newRow = dt.NewRow();

  //     int i = d.IndexOf('%');
  //     if (i != -1) {
  //         newRow["DType"] = "R";
  //         newRow["DVal"] = Convert.ToDecimal(d.Substring(0, i - 1));
  //     }
  //     else {
  //         newRow["DType"] = "B";
  //         newRow["DVal"] = Convert.ToDecimal(d);
  //     }

  //     string dtype = PxConvert.ToString(newRow["DType"]);
  //     decimal dval = PxConvert.ToDecimal(newRow["DVal"]);
  //     string discount = dval.ToString(PxFormat.DecimalFormat);
  //     if (dtype == "R") discount += "% "; else discount += " ";
  //     newRow["DText"] = discount;

  //     dt.Rows.Add(newRow);
  // }
  // return dt;
  //     return null
  // }

  static getDiscountList(inputStr: string, sorted: boolean): string[] {
    let decimalOptions = {
      style: 'decimal',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    };

    inputStr = inputStr.replace(/\.\.+/g, '.');
    inputStr = inputStr.replace(/%%+/g, '%');
    inputStr = inputStr.replace(/\++/g, '+');
    inputStr = inputStr.replace(/\,/g, '');

    let allDisc: string[] = [];

    let discList = inputStr.removeAll(',').split('+');
    let i = 0;

    while (i < discList.length) {
      let s = discList[i].trim();
      if (s == '' || Number(s) == 0) {
        i++;
        continue;
      }

      if (s.indexOf('%') > 0) {
        s = s.replace('%', '');

        let num = Number(s);
        if (!isNaN(num) && num <= 100)
          // CAN PARSE TO NUMBER
          allDisc.push(num.toLocaleString('th', decimalOptions) + '%');
      } else {
        let num = Number(s);

        if (!isNaN(num)) {
          let numStr = num.toLocaleString(undefined, decimalOptions);
          if (s.indexOf('.') > 0) allDisc.push(numStr);
          else {
            if (num <= 100) allDisc.push(numStr + '%');
            else allDisc.push(numStr);
          }
        }
      }
      i++;
    }

    // Remove Zero Value

    i = 0;
    while (i < allDisc.length) {
      let s = allDisc[i];
      if (s.indexOf('%') > 0) s = s.replace('%', '');
      if (Number(s) == 0) allDisc.splice(i, 0);
      else i++;
    }

    if (!sorted) {
      return allDisc;
    }

    let cashDisc: number[] = [];
    let percDisc: number[] = [];

    allDisc.forEach((s) => {
      s = s.replace(/\,/g, ''); // Remove comma ',' in number

      if (s.indexOf('%') > 0) {
        s = s.replace('%', '');
        percDisc.push(Number(s));
      } else {
        cashDisc.push(Number(s));
      }
    });

    cashDisc = cashDisc.sort((n1, n2) => n1 - n2);
    percDisc = percDisc.sort((n1, n2) => n1 - n2);

    allDisc = [];
    cashDisc.forEach((d) => {
      allDisc.push(d.toLocaleString(undefined, decimalOptions));
    });
    percDisc.forEach((d) => {
      allDisc.push(d.toLocaleString(undefined, decimalOptions) + '%');
    });

    return allDisc;
  }
  static getDiscountStrings(discList: string[]): string {
    let discString = '';
    discList.forEach((s) => {
      discString += (discString == '' ? '' : ' + ') + s;
    });
    return discString;
  }

  static getDiscountString(discString: string, sorted: boolean): string {
    if (Number(discString.removeAll(',')) == 0) return '';
    else return this.getDiscountStrings(this.getDiscountList(discString, sorted));
  }

  static getDiscountValue(baseValue: number, discString: string): number {
    let isMinusBaseValue = baseValue < 0;

    if (discString == '' || baseValue == 0) return 0;

    let discountAmt = 0;
    let discount = 0;
    let stepDiscString: string[] = discString.removeAll(',').split('+');

    stepDiscString.forEach((s) => {
      if (s.indexOf('%') != -1) {
        discount = Number(s.replace('%', ''));
        discountAmt += (baseValue * discount) / 100;
        baseValue -= (baseValue * discount) / 100;
      } else {
        discount = Number(s);
        discountAmt += isMinusBaseValue ? -discount : discount;
        baseValue -= isMinusBaseValue ? -discount : discount;
      }
    });

    return discountAmt;
  }
}
 

export enum FileTypes {
  Csv = 'csv',
  Excel = 'xlsx',
  Pdf = 'pdf',
}

export class PxFile {
  static convertImageFileToBase64(filePath): Promise<any[]> {
    return Promise.resolve(null);
    // ..#..
    // let http = new HttpClient(new HttpXhrBackend({ build: () => new XMLHttpRequest() }));

    // return new Promise((resolve, reject) => {

    //     http.get(filePath, { responseType: 'blob' })
    //         .subscribe(res => {
    //             const reader = new FileReader();
    //             reader.onload = () => {
    //                 let base64data = PxConvert.dataUrlToBase64(reader.result)
    //                 resolve(base64data)
    //             }
    //             reader.onerror = err => {
    //                 reject(err)
    //             };
    //             reader.readAsDataURL(res);
    //         })
    // })
  }
}

export class PxExport {
  /** json: any[] = [ {name:'chukiat, age:20}, {name:'manee',age:21} ] */
  static jsonToExcel(json: any[], excelFileName: string): void {
    //  ..#..
    // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    // const wb: XLSX.WorkBook = XLSX.utils.book_new();
    // XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    // /* save to file */
    // XLSX.writeFile(wb, excelFileName + '.xlsx');
  }

  /** json: any[] = [ {name:'chukiat, age:20}, {name:'manee',age:21} ] */
  static jsonToCsv(json: any[], csvFileName: string): void {
    let csvData = this.convertToCSV(json);
    let a = document.createElement('a');
    a.setAttribute('style', 'display:none;');
    document.body.appendChild(a);
    var blob = new Blob([csvData], { type: 'text/csv' });
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = csvFileName + '.csv';
    a.click();
  }

  private static convertToCSV(objArray) {
    var array = typeof objArray != 'object' ? JSON.parse(objArray) : objArray;
    var str = '';
    var row = '';

    for (var index in objArray[0]) {
      //Now convert each value to string and comma-separated
      row += index + ',';
    }
    row = row.slice(0, -1);
    //append Label row with line break
    str += row + '\r\n';

    for (var i = 0; i < array.length; i++) {
      var line = '';
      for (var index in array[i]) {
        if (line != '') line += ',';

        line += array[i][index];
      }
      str += line + '\r\n';
    }
    return str;
  }
}

// export function inputDecimal(str, maxD) {
//   str = str.trim();

//   if (str == '') return str;
//   if (str == '-') return str;
//   if (str == '.') return '0.';

//   str = str.removeAll(',');

//   if (isNaN(str)) {
//     str = str.substr(0, str.length - 1);
//   }

//   if (str.endsWith('.')) {
//     return parseFloat(str).formatDecimal(0, 0) + '.';
//   } else if (str.includes('.')) {
//     const pointLen = str.split('.')[1].length;
//     const minD = pointLen > maxD ? maxD : pointLen;
//     return parseFloat(str).formatDecimal(minD, maxD);
//   } else {
//     return parseFloat(str).formatDecimal(0, maxD);
//   }
// }

export function getPosibleCashReceivableValueAmount(cashAmout):Array<number> {
  let value = cashAmout;
  let result = [];

  if (cashAmout <= 0) return result;

  // AVAILABLE THAI MONEY
  const bank10 = 10;
  const bank20 = 20;
  const bank50 = 50;
  const bank100 = 100;
  const bank500 = 500;
  const bank1000 = 1000;

  value = Math.ceil(value);

  let r = value % 10;
  let base10 = (value % 100) - r;
  let v = value - r;

  if (r > 0) {
    result.push(v + bank10);
    result.push(v + bank20);
  }

  r = value % 100;
  var base100 = (value % 1000) - r;
  v = value - r;

  if (r > 0) {
    if (base10 < bank50) {
      result.push(v + bank50);
    }

    result.push(v + bank100);
  }

  r = value % 1000;
  v = value - r;

  if (r > 0) {
    if (base100 < bank500) {
      result.push(v + bank500);
    }

    result.push(v + bank1000);
  }

  if (!result.length) return result;

  // SORT
  result = result.sort((n1, n2) => n1 - n2);

  if (result.length < 8) {
    var lastValue = result[result.length - 1]; // Last
    if (cashAmout > 100 && cashAmout < 1000 && lastValue > cashAmout) {
      r = cashAmout % 100;
      result.push(lastValue + r);
    }
  }

  // REMOVE DUPPLICATE ELEMEMNT IN ARRAY
  result = [...new Set(result)];

  return result.sort((n1, n2) => n1 - n2);
}

export function validateEmail(email) {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

export function validatePeopleId(id) {
  const value = (id ?? '').removeAll(' ');

  if ((value ?? '').isEmpty()) return true;

  if (value.length != 13) return false;

  if (!value.isNumber()) return false;

  let sum = 0;

  for (let i = 0; i < value.length - 1; i++) sum += parseInt(value[i]) * (13 - i);

  const v = (11 - (sum % 11)) % 10;

  return parseInt(value[12]) === v;
}

/** atlease 1 year */
export function validateBirthDate(date) {
  if (date == null || date == undefined) return true;
  else return moment().diff(date, 'years') >= 1;
}

export function hideCreditCardNumber(cardNumber: string): string {
  if (cardNumber.isEmpty() || cardNumber.length < 5) return '';

  let result = '';
  for (let i = 0; i < cardNumber.length - 4; i++) {
    const element = cardNumber[i];
    if (element >= '0' && element <= '9') result += 'X';
    else result += element;
  }

  return result + cardNumber.substr(cardNumber.length - 4, 4);
}

export function alertMessage({ title, message }) {
  //xx
  // Alert.alert(title, message, [{ text: getText('ตกลง', 'OK'), onPress: () => {} }], { cancelable: true });
}

export function confirmDelete({ name, onConfirm }) {
  //xx
  // Alert.alert(
  //   getText('ลบข้อมูล', 'Delete'),
  //   (name ? "'" + name + "'  " : '') + getText('คุณแน่ใจหรือไม่ว่าจะลบข้อมูลนี้อย่างถาวร?', 'Are you sure you want to delete this item?'),
  //   [
  //     { text: getText('ยกเลิก', 'Cancel'), style: 'cancel', onPress: () => {} },
  //     {
  //       text: getText('ลบ', 'Delete'),
  //       style: 'destructive',
  //       onPress: onConfirm,
  //     },
  //   ],
  //   { cancelable: true }
  // );

  // title = (!title) ? translate('ConfirmDelete') : title
  // let icon = 'fas fa-trash-alt fa-3x';

  // messageHtml = `
  // <div class="d-flex">
  //     <div class="p-2"><i class="${icon}"></i></div>
  //     <div class="p-2 align-self-center" style="min-width:250px; max-width: 400px;"><span>${messageHtml}</span></div>
  // </div> `

  // let myDialog = custom({
  //     title: title,
  //     messageHtml: messageHtml,
  //     buttons: Btn.btnOKCancel
  // })

  // let r: any

  // try {
  //     PxPopup.add(myDialog); r = await myDialog.show(); PxPopup.remove(myDialog)
  // } catch { r = null } finally { PxPopup.remove(myDialog); }

  // return r

  return null;
}
