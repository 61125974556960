import "src/inventory/Inventory.css";
import "src/utils/extensions";

import { useEffect, useState } from "react";
import { PxData, SqlDataResponse } from "src/library/data-api";

import { SearchOutlined } from "@mui/icons-material";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import * as React from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";

import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { API_URL_HOST } from "src/globals/constants";
import axios from "axios";

export default function InventoryScreen({}) {
  useEffect(() => {
    const dbID = localStorage.getItem("dbid");
    PxData.default = new PxData(dbID);
  }, []);

  const [searching, setSearching] = useState(false);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const onChangeSearchQuery = (event) => {
    setSearchQuery(event.target.value);
  };

  const [dataSource, setDataSource] = useState([]);


// useEffect(() => {
//   let url = API_URL_HOST + "/getConnectionString/Demo/@535BE";
//   axios
//         .get(url, { 
//           headers: { 
//             Accept: "application/json",
//             "Content-Type": "application/json; charset=utf-8",
//           },
//         })
//         .then((response) => {
//           console.log(
//           new SqlDataResponse(response.data));
//         });
        
// }, []);

  useEffect(() => {}, [dataSource]);

  function fetchData(event) {
    event.preventDefault();

    let text = searchQuery;

    if ((text ?? "") == "") return;

    const searchFields = ["MPCode", "BCCode", "TMPName", "EMPName"];

    let searchWords = [];
    let searchString = "";

    // STEP 1: searchQuery = 885 Shirt

    text
      .toSingleSpace()
      .split(" ")
      .map((e) => {
        searchWords.push(`'%${e}%'`);
      });

    //STEP 2: searchWords = ["'%885%'", "'%Shirt%'"]

    let searchs = [];
    searchFields.map((fieldname) => {
      searchWords.map((word) => {
        searchs.push(`( ${fieldname} LIKE ${word} )`);
      });
    });

    searchString = searchs.join(" OR ");

    //STEP 3: searchString =   ( MPCode LIKE '%885%' ) OR ( MPCode LIKE '%Shirt%' ) OR ( TMPName LIKE '%885%' ) OR ( TMPName LIKE '%Shirt%' ) OR ( EMPName LIKE '%885%' ) OR ( EMPName LIKE '%Shirt%' ) OR ( BCCode LIKE '%885%' ) OR ( BCCode LIKE '%Shirt%' )

    const criteria = ` (${searchString})  `;

    const sql = `    
    -- รายงานหน่วยย่อยเท่านั้น  
      SELECT  TOP 100 MP.Id MP_Id, MP.MPCode, MP.TMPName, MP.EMPName, UM.TLUName TUMName_SU, UM.ELUName EUMName_SU, PX.Picture,
              BC.Id BC_Id, BC.BCCode, BC.Price1  Price, 
              BC.Price1, BC.Price2, BC.Price3, BC.Price4, BC.Price5, BC.Price6, BC.Price7, BC.Price8, BC.Price9, BC.Price10
      FROM    MAS_MP MP
                  LEFT OUTER JOIN MAS_MP_BC BC ON BC.MP_Id = MP.Id                   
                  LEFT OUTER JOIN MAS_MP_PX PX ON PX.MP_Id = MP.Id AND PX.PxNo = 1                   
                  LEFT OUTER JOIN MAS_LU UM ON UM.Id = MP.UM_Id_SU
      WHERE   MP.Active = 'True' AND  ${criteria}
      ORDER   BY LEN(MP.MPCode), MP.MPCode
      `;

    setSearching(true);
    PxData.default
      .getDataSet(sql)
      .then((res) => {
        setDataSource(res.tables[0]);
      })
      .catch((error) => {
        
        console.log(error);
      })
      .finally(() => setSearching(false));
  }

  // *render* \\

  return (
    <div style={{ margin: "auto", alignItems: "center", justifyContent: "center" }}>
      <div style={{ flex: 1, backgroundColor: "transparent", display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <div style={{ display: "flex", justifyContent: "center", padding: 10 }}>
          <Typography variant="h6">Inventory (Stock Onhand)</Typography>
        </div>
        <Box style={{ backgroundColor: "transparent", display: "flex", justifyContent: "center" }} component="form" noValidate autoComplete="off" onSubmit={(e) => fetchData(e)}>
          <TextField
            id="outlined-basic"
            InputProps={{
              startAdornment: <InputAdornment position="start">{searching ? <CircularProgress size={24} /> : <SearchOutlined />}</InputAdornment>,
            }}
            // variant="outlined"
            placeholder="Enter Item sku or name or barcode"
            autoFocus
            style={{ backgroundColor: "white", width: 500, maxWidth: "90%" }}
            value={searchQuery}
            onChange={onChangeSearchQuery}
          />
        </Box>
        <div style={{ padding: 20 }}>
          <InvTable dataSource={dataSource} />
        </div>
      </div>
    </div>
  );
}

function InvTable({ dataSource }) {
  //
  const [openDetail, setOpenDetail] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState<any>();

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Image</StyledTableCell>
              <StyledTableCell>Sku</StyledTableCell>
              <StyledTableCell>Barcode</StyledTableCell>
              <StyledTableCell>Description</StyledTableCell>
              <StyledTableCell align="right">Price</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataSource.map((row) => (
              <StyledTableRow key={row.BC_Id}>
                <StyledTableCell component="th" scope="row">
                  <Avatar src={`data:image/jpeg;base64,${row.Picture}`} sx={{ width: 50, height: 50 }} />
                </StyledTableCell>
                <StyledTableCell
                  onClick={() => {
                    setSelectedRow(row);
                    setOpenDetail(true);
                  }}
                >
                  <Button variant="text">{row.MPCode}</Button>
                </StyledTableCell>
                <StyledTableCell>{row.BCCode}</StyledTableCell>
                <StyledTableCell>{row.TMPName}</StyledTableCell>
                <StyledTableCell align="right">{(row.Price1 ?? 0).formatDecimal(2, 2)}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* ... */}

      <CustomizedDialogs row={selectedRow} open={openDetail} onClose={() => setOpenDetail(false)} />
    </>
  );
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(props.open);
  const [row, setRow] = React.useState(props.row);

  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (props.open && props.row) {
      setOpen(props.open);
      setRow(props.row);
      fetchData();
    }
  }, [props.open, props.row]);

  const handleClose = () => {
    setOpen(false);
    props.onClose();
  };

  function fetchData() {
    const row = props.row;
    const sql = `      
      
      -- เฉพาะรายการที่ค้นหา 
      DECLARE	@TableMP_Id TableTypeMP_Id	
      INSERT  @TableMP_Id VALUES(${row.MP_Id})    

      EXEC sp_StockCardProcessPartialPending @TableMP_Id

      -- เฉพาะคลังหลัก 
      DECLARE	@TableWH_Id TableTypeWH_Id 	
      INSERT	@TableWH_Id SELECT WH_Id_DF FROM MAS_BH WHERE WH_Id_DF IS NOT NULL AND Active = 'True'
      
      -- ดึงสต็อกขึ้นมาทุกคลัง (เฉพาะคลังหลัก)
      DECLARE	@stock TABLE(WH_Id int, MP_Id int, Qty decimal(19,6), UnitPrice decimal(19,6), Val decimal(19,6))
      INSERT	@stock 
      SELECT	* FROM	tv_StockBalance (null, @TableWH_Id, @TableMP_Id)
     
      SELECT	S.MP_Id, BH.BHCode, BH.TBHName BHName, BH.PriceNo, S.Qty
      FROM	  @stock  S, MAS_WH WH, MAS_BH BH
      WHERE	  S.WH_Id = WH.Id AND WH.BH_Id = BH.Id 
      ORDER	  BY S.MP_Id, BH.TBHName `;

    setLoading(true);

    setTimeout(() => {
      PxData.default
        .getDataSet(sql)
        .then((res) => {
          // show stock of each branch when item press
          setDataSource(res.tables[0]);
          // console.log(res.tables[0]);
        })
        .catch((error) => console.log(error))
        .finally(() => setLoading(false));
    }, 500);
  }

  return (
    <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {row && (
          <div style={{ marginRight: 40 }}>
            <b>{row.MPCode}</b> <span>{row.TMPName}</span>
          </div>
        )}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <div style={{ flex: 1, minHeight: 200 }}>
          {loading ? (
            <div style={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
              <CircularProgress color="inherit" />
            </div>
          ) : (
            <>
              <DenseTable dataSource={dataSource} />
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        {/* <Button autoFocus onClick={handleClose}>
          Save changes
        </Button> */}
      </DialogActions>
    </BootstrapDialog>
  );
}

function DenseTable({ dataSource }) {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 200 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>Branch</TableCell>
            <TableCell align="right">Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataSource.map((row) => (
            <TableRow key={row.BHCode} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.BHCode} {row.BHName}
              </TableCell>
              <TableCell align="right">{row.Qty}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
