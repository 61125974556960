
// export const dbID = 'DELMAR';


/** /dbHub  มี case-sensitive ด้วย ไม่งั้น connect ไม่ได้ เพราะมันเป็น nginx */
// export const SIGNAL_R_URL_HOST = 'https://18.136.73.15/dbHub';
// export const SIGNAL_R_URL_HOST = 'https://api.posware.net/dbHub';


export const SIGNAL_R_URL_HOST = 'https://api.posware.net/dbHub';
export const SIGNAL_R_URL_HOST_LOCAL = 'https://localhost:7030/dbHub';

  
export const API_URL_HOST =   'https://api.posware.net/api';
export const API_URL_HOST_LOCAL = 'https://localhost:7030/api';

export const MONTH_FULLNAME = {
  th: [
    'มกราคม',
    'กุมภาพันธ์',
    'มีนาคม',
    'เมษายน',
    'พฤษภาคม',
    'มิถุนายน',
    'กรกฏาคม',
    'สิงหาคม',
    'กันยายน',
    'ตุลาคม',
    'พฤศจิกายน',
    'ธันวาคม',
  ],
  en: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

export const MONTH_SHORTNAME = {
  th: [
    'ม.ค.',
    'ก.พ.',
    'มี.ค.',
    'เม.ย.',
    'พ.ค.',
    'มิ.ย.',
    'ก.ค.',
    'ส.ค.',
    'ก.ย.',
    'ต.ค.',
    'พ.ย.',
    'ธ.ค.',
  ],
  en: [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ],
};

export const DAY_FULLNAME = {
  th: ['อาทิตย์', 'จันทร์', 'อังคาร', 'พุธ', 'พฤหัสบดี', 'ศุกร์', 'เสาร์'],
  en: [
    'Sunday',
    'Monday',
    'Tueday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ],
};
 

export const AP_COLOR = '#0081B4';
export const AR_COLOR = '#10A19D';
export const ST_COLOR = '#C27664';


