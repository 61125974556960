export const sql_sum_sls = `


-- GET 2 YEAR DATA


DECLARE @queryFor varchar(255) = '#queryFor'

-- FIRST DAY OF LAST YEAR 
DECLARE @sDate date = ( SELECT DATEADD(yy, DATEDIFF(yy, 0, GETDATE()) - 1, 0) )
-- LAST DAY OF CURR AST YEAR 
DECLARE @eDate date = ( SELECT DATEADD(yy, DATEDIFF(yy, 1, GETDATE()) + 1, 0) -1)



-- FIRST DAY OF THIS MONTH
DECLARE @sDateOfMonth date =  ( SELECT DATEADD(mm, DATEDIFF(mm, 0, GETDATE()), 0)  )
-- LAST DAY OF THIS MONTH
DECLARE @eDateOfMonth date =  ( SELECT DATEADD(mm, DATEDIFF(mm, -1, GETDATE()), -1)  )



IF @queryFor = 'infoBox_monthlySalesSummary'
BEGIN

	-------- Table (Info-Box) ----------


	-- By Date, SUM(Net), SUM(Trn)
	SELECT ADate, ADay, ISNULL(Net,0) Net, ISNULL(Trn,0) Trn FROM dbo.tv_GetDate(@sDate,@eDate) A LEFT OUTER JOIN (
		SELECT DocDate, SUM(AI_Net+HS_Net+DR_Net+SR_Net+PX_Net+PG_Net) Net, SUM(AI_Trn+HS_Trn+DR_Trn+SR_Trn+PX_Trn+PG_Trn) Trn FROM SUM_SLS  WHERE DocDate BETWEEN @sDate AND @eDate GROUP BY DocDate
	) B ON A.ADate = B.DocDate
 

	-------- Table1 (Monthly Sales Summary) ----------

	-- By Year, Month, SUM(Net), SUM(Trn)
	SELECT AYearNo AYear,  AMonthNo AMonth, TMonthName, EMonthName, ISNULL(Net,0) Net, ISNULL(Trn,0) Trn FROM dbo.tv_GetMonth(@sDate,@eDate,0) A LEFT OUTER JOIN (
		SELECT  YEAR(DocDate) AYear, MONTH(DocDate) AMonth, SUM(AI_Net+HS_Net+DR_Net+SR_Net+PX_Net+PG_Net) Net, SUM(AI_Trn+HS_Trn+DR_Trn+SR_Trn+PX_Trn+PG_Trn) Trn FROM SUM_SLS  WHERE DocDate BETWEEN @sDate AND @eDate GROUP BY YEAR(DocDate), MONTH(DocDate)
	) B ON A.AYearNo = B.AYear AND A.AMonthNo = B.AMonth

END


ELSE IF @queryFor = 'topFivePerson'
BEGIN

	-------- Table ----------

	SELECT TOP 5 ROW_NUMBER() OVER(ORDER BY SUM(P.Net) DESC) No, P.ST_Id, ST.TSTName STName, ST.TFullName FullName, BH.TBHName BHName, PX.Picture, SUM(P.Net) Net FROM 
	(
		SELECT ST_Id, SUM(Net) Net FROM POS_PT WHERE AbbDate BETWEEN @sDateOfMonth AND @eDateOfMonth AND AbbStatus = 'N' GROUP BY ST_Id UNION ALL 
		SELECT ST_Id, SUM(Net) Net FROM DOC_AR_AR WHERE DocDate BETWEEN @sDateOfMonth AND @eDateOfMonth AND DocType = 'AR_HS' AND DocStatus NOT IN (0,-1) GROUP BY ST_Id 
	)	P	LEFT OUTER JOIN MAS_ST ST ON ST.Id = P.ST_Id LEFT OUTER JOIN MAS_BH BH ON BH.Id = ST.BH_Id LEFT OUTER JOIN MAS_ST_PX PX ON PX.ST_Id = P.ST_Id AND PX.PxNo = 1 
	GROUP	BY P.ST_Id, ST.TSTName, ST.TFullName, PX.Picture, BH.TBHName 
	ORDER	BY SUM(Net) DESC

END

ELSE IF @queryFor = 'todaySalesRanking'
BEGIN

	-------- Table ----------

	-- CURRENT DATE (date only not time)
	DECLARE @aDate date = GETDATE() -- '2022.03.20'

	SELECT ROW_NUMBER() OVER(ORDER BY Net DESC) No,* FROM 
	(
		SELECT BH_Id, BH.TBHName BHName, SUM(AI_Net+HS_Net+DR_Net+SR_Net+PX_Net+PG_Net) Net, SUM(AI_Trn+HS_Trn+DR_Trn+SR_Trn+PX_Trn+PG_Trn) Trn FROM SUM_SLS S LEFT OUTER JOIN MAS_BH BH ON BH.Id = S.BH_Id WHERE DocDate = @aDate GROUP BY BH_Id, BH.TBHName
	)	P
END

ELSE IF @queryFor = 'bestSalesItems'
BEGIN

	-------- Table ----------

	-- 0 : Month, 1 Week
	DECLARE	@sku_Best_Sale_Option int = #bestSalesItemsOption

	DECLARE @sDate_SKU_BestSales date = GETDATE() 
	DECLARE @eDate_SKU_BestSales date = GETDATE()

	IF @sku_Best_Sale_Option = 0	-- MONTHLY
	BEGIN
		SET	@sDate_SKU_BestSales = ( SELECT DATEADD(mm, DATEDIFF(mm, 0, GETDATE()), 0) )
		SET	@eDate_SKU_BestSales = ( SELECT DATEADD(mm, DATEDIFF(mm, -1, GETDATE()), -1) )

	END ELSE 

	IF @sku_Best_Sale_Option = 1	-- WEEKLY
	BEGIN
		-- START WEEK BY SUNDAY --
		SET	@sDate_SKU_BestSales = (SELECT DATEADD(DD, 1 - DATEPART(DW, GETDATE()), GETDATE()))
		SET	@eDate_SKU_BestSales = DATEADD(DD, 6, @sDate_SKU_BestSales)
	END

		
	DECLARE	@MPType_FixedSet int = 102
	DECLARE	@MPType_NonFixedSet int = 103
	DECLARE	@MPType_BOM int = 104
	DECLARE	@MPType_RawMaterial int = 4

	SELECT	TOP 10 ROW_NUMBER() OVER(ORDER BY Qty DESC) No, MP.MPCode, MP.TMPName MPName, BC.Price1 ListPrice, D.*, PX.Picture 
	FROM (
		SELECT	P.MP_Id, SUM(P.Qty) Qty, SUM(P.Net) Net			
		FROM		
		(
			SELECT	I.MP_Id, SUM(CASE WHEN H.DocType = 'AR_HS' THEN  I.Qty ELSE -I.Qty END) Qty, SUM(I.Net) Net
			FROM	DOC_AR_AR H, DOC_SX_I I
			WHERE	(H.Id = I.DocId) AND (H.DocType = I.DocType) AND 
					(H.DocType IN ('AR_HS','AR_SR')) AND 
					(H.DocStatus NOT IN (0,-1)) AND
					(H.DocDate	BETWEEN @sDate_SKU_BestSales AND @eDate_SKU_BestSales) AND 
					(I.MPType NOT IN (@MPType_FixedSet,@MPType_NonFixedSet))
			GROUP BY I.MP_Id			

			UNION ALL	------- 
		
			SELECT	PI.MP_Id, SUM(PI.Qty) Qty, SUM(PI.Net+ISNULL(PI.PR_Net,0)) Net 
			FROM	POS_PI PI
			WHERE	(PI.AbbType IN ('S','R') AND PI.AbbStatus = 'N' AND PI.ItemVoided = 'False' AND (PI.ItemAu = 'False' OR PI.ItemNo_PS IS NOT NULL)) AND  
					(PI.AbbDate	BETWEEN @sDate_SKU_BestSales AND @eDate_SKU_BestSales) AND (PI.MPType NOT IN (@MPType_FixedSet,@MPType_NonFixedSet)) 
			GROUP BY PI.MP_Id

		)	P			

	GROUP	BY	P.MP_Id

	)	D	 
		LEFT OUTER JOIN MAS_MP		MP ON MP.Id = D.MP_Id
		LEFT OUTER JOIN MAS_MP_BC	BC ON BC.Id = MP.BC_Id_DF  
		LEFT OUTER JOIN MAS_MP_PX	PX ON PX.MP_Id = D.MP_Id AND PX.PxNo = 1

	ORDER	BY Qty DESC

END


`