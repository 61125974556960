import i18n from 'src/i18n/i18n';
import { MONTH_FULLNAME, MONTH_SHORTNAME } from 'src/globals/constants';
import 'intl';
import 'intl/locale-data/jsonp/th-TH';
import moment from 'moment';

export enum RoundMoneyType {
  Satang = 0,
  OneBaht = 1,
  FiveBaht = 5,
  TenBaht = 10,
}
export enum RoundMoneyMethod {
  Actual = 0,
  AlwayRoundDown = -1,
  AlwayRoundUp = 1,
}

/** ARRAY PROTOTYPE ****************************** */

declare global {
  interface Array<T> {
    except(val: T[]): T[];
  }
}


  Array.prototype.except = function <T>(val: T[]) {
    return this.filter((x: any) => !val.includes(x));
  };


// Array.prototype.except = function (val: []) {
//   return this.filter(function (x) {
//     return !val.includes(x);
//   });
// };

/** DATE PROTOTYPE ****************************** */

declare global {
  interface Date {
    isEmpty(): boolean;
    withoutTime(): Date;
    isBetween(minDate: Date, maxDate: Date): boolean;
    isGreaterThan(date: Date): boolean;
    isLessThan(date: Date): boolean;
    // formatDate(format: string, locale?: string): string;
    toCentury(): Date;

    addDays(n): Date;
    addMonths(n): Date;
    addYears(n): Date;

    setTimeFromString(t): Date;
    getTimeString(): string;

    firstDayOfMonth(): Date;
    lastDayOfMonth(): Date;

    firstDayOfYear(): Date;
    lastDayOfYear(): Date;

    fullMonthName(locale): string;
    shortMonthName(locale): string;
  }
}

Date.prototype.isEmpty = function () {
  return this == null || this == undefined;
};
Date.prototype.withoutTime = function () {
  var d = new Date(this);
  d.setHours(0, 0, 0, 0);
  return d;
};
Date.prototype.isBetween = function (minDate: Date, maxDate: Date): boolean {
  if (!this.getTime) throw new Error('isBetween() was called on a non Date object');

  return (!minDate ? true : this.getTime() >= minDate.getTime()) && (!maxDate ? true : this.getTime() <= maxDate.getTime());
};
Date.prototype.isGreaterThan = function (date: Date): boolean {
  if (!this.getTime) throw new Error('isGreaterThan() was called on a non Date object');

  return !date ? true : this.getTime() > date.getTime();
};
Date.prototype.isLessThan = function (date: Date): boolean {
  if (!this.getTime) throw new Error('isLessThan() was called on a non Date object');

  return !date ? true : this.getTime() < date.getTime();
};
// Date.prototype.formatDate = function (format: string, locale: string = 'en-US'): string {
//   let date = this;
//   moment.locale(locale);
//   return moment(date).format(format);
// };
Date.prototype.toCentury = function () {
  let date = new Date(this);
  let year = date.getFullYear();
  if (year >= new Date().getFullYear() + 10) {
    date.setFullYear(year - 543);
  }
  return date;
};

Date.prototype.addDays = function (n) {
  return new Date(this.setDate(this.getDate() + n));
};
Date.prototype.addMonths = function (n) {
  return new Date(this.setMonth(this.getMonth() + n));
};
Date.prototype.addYears = function (n) {
  return new Date(this.setFullYear(this.getFullYear() + n));
};

/** t is format '23:59:00' */
Date.prototype.setTimeFromString = function (t: string) {
  let date = new Date(this);
  let e: string[] = t.split(':');
  let h = <number>(<unknown>e[0]);
  let m = <number>(<unknown>e[1]);
  let s = <number>(<unknown>e[2]);

  date.setHours(h, m, s);
  return date;
};

/** return in format '23:59:00' */
Date.prototype.getTimeString = function () {
  let date = new Date(this);
  return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
};

Date.prototype.firstDayOfMonth = function () {
  return new Date(this.getFullYear(), this.getMonth(), 1);
};
Date.prototype.lastDayOfMonth = function () {
  return new Date(this.getFullYear(), this.getMonth() + 1, 0);
};

Date.prototype.firstDayOfYear = function () {
  return new Date(this.getFullYear(), 1, 1);
};
Date.prototype.lastDayOfYear = function () {
  return new Date(this.getFullYear(), 12, 0);
};

Date.prototype.fullMonthName = function (locale: string) {
  return MONTH_FULLNAME[locale][this.getMonth()];
};

Date.prototype.shortMonthName = function (locale: string) {
  return MONTH_SHORTNAME[locale][this.getMonth()];
};

/************************************************** */

/** NUMBER PROTOTYPE ****************************** */

declare global {
  interface Number {
    round(precision: number): number;
    getVal(vatType: number, vatRate: number): number;
    getVat(vatType: number, vatRate: number): number;
    roundUp(places: number): number;
    roundDown(places: number): number;
    roundFactor(places: number): number;
    roundMoney(roundType: RoundMoneyType, roundMethod: RoundMoneyMethod): number;
    formatDecimal(minimumFractionDigits?: number, maximumFractionDigits?: number): string;
    isBetween(minValue: number, maxValue: number): boolean;
  }
}

Number.prototype.round = function (precision: number) {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(this * multiplier) / multiplier;
};

Number.prototype.getVal = function (vatType, vatRate) {
  if (vatType == 0) return (this * 100) / (100 + vatRate);
  else if (vatType == 1) return this;
  else if (vatType == 2) return this;
  return this;
};

/** VatTypes { Inclusive = 0, Exclusive = 1, NonVat }  *  Return Vat Amount  */
Number.prototype.getVat = function (vatType, vatRate) {
  if (vatType == 0) return (this * vatRate) / (100 + vatRate);
  else if (vatType == 1) return this * (vatRate / 100);
  else if (vatType == 2) return 0;
  return 0;
};

Number.prototype.roundUp = function (places: number): number {
  let number = this;
  let factor = this.roundFactor(places);
  number *= factor;
  number = Math.ceil(this);
  number /= factor;
  return number;
};

Number.prototype.roundDown = function (places: number): number {
  let number = this;
  let factor = this.roundFactor(places);
  number *= factor;
  number = Math.floor(number);
  number /= factor;
  return number;
};

Number.prototype.roundFactor = function (places: number): number {
  let number = this;
  let factor = 1;

  if (places < 0) {
    places = -places;
    for (let i = 0; i < places; i++) {
      factor /= 10;
    }
  } else {
    for (let i = 0; i < places; i++) {
      factor *= 10;
    }
  }

  return factor;
};

Number.prototype.roundMoney = function (roundType: RoundMoneyType, roundMethod: RoundMoneyMethod): number {
  let baseValue = this;

  if (baseValue == 0) return 0;

  let backupBaseValue = baseValue; // Backup ค่าบวกลบของ BaseValue
  baseValue = Math.abs(baseValue);

  let rValue = 0;

  //#region Satang
  if (roundType == RoundMoneyType.Satang) {
    if (baseValue > 0) rValue = baseValue - Math.floor(baseValue);
    // Negative Value
    else rValue = baseValue - Math.ceil(baseValue);

    rValue = Math.trunc(rValue * 100); // Example   0.858 => 85

    if (rValue == 0) rValue = 0;
    else {
      if (roundMethod == RoundMoneyMethod.Actual) {
        if (rValue >= 1 && rValue <= 12) rValue = 0;
        else if (rValue >= 13 && rValue <= 35) rValue = 25;
        else if (rValue >= 36 && rValue <= 59) rValue = 50;
        else if (rValue >= 60 && rValue <= 83) rValue = 75;
        else if (rValue >= 84 && rValue <= 99) rValue = 100;
      } else if (roundMethod == RoundMoneyMethod.AlwayRoundDown) {
        if (rValue >= 1 && rValue <= 24) rValue = 0;
        else if (rValue >= 25 && rValue <= 49) rValue = 25;
        else if (rValue >= 50 && rValue <= 74) rValue = 50;
        else if (rValue >= 75 && rValue <= 99) rValue = 75;
        else rValue = 100;
      } else if (roundMethod == RoundMoneyMethod.AlwayRoundUp) {
        if (rValue >= 1 && rValue <= 25) rValue = 25;
        else if (rValue >= 26 && rValue <= 50) rValue = 50;
        else if (rValue >= 51 && rValue <= 75) rValue = 75;
        else if (rValue >= 76 && rValue <= 99) rValue = 100;
      }
    }

    baseValue = Math.trunc(baseValue) + rValue / 100;
  }
  //#endregion
  //#region OneBaht
  else if (roundType == RoundMoneyType.OneBaht) {
    if (roundMethod == RoundMoneyMethod.Actual) {
      baseValue = Math.round(baseValue);
    } else if (roundMethod == RoundMoneyMethod.AlwayRoundDown) {
      baseValue = this.roundDown(baseValue, 0);
    } else if (roundMethod == RoundMoneyMethod.AlwayRoundUp) {
      baseValue = this.roundUp(baseValue, 0);
    }

    //if (baseValue == 0) baseValue = 1;
  }
  //#endregion
  //#region FiveBaht
  else if (roundType == RoundMoneyType.FiveBaht) {
  }
  //#endregion
  //#region TenBaht
  else if (roundType == RoundMoneyType.TenBaht) {
  }
  //#endregion

  if (backupBaseValue < 0) baseValue = -baseValue;

  return baseValue;
};

Number.prototype.formatDecimal = function (minimumFractionDigits = 2, maximumFractionDigits = 2): string {
  let value = this ?? 0;

  let decimalOptions = {
    style: 'decimal',
    minimumFractionDigits: minimumFractionDigits,
    maximumFractionDigits: maximumFractionDigits,
  };
  return (value ?? 0).toLocaleString(undefined, decimalOptions);
};

Number.prototype.isBetween = function (minValue: number, maxValue: number): boolean {
  let value = this;
  return value >= minValue && value <= maxValue;
};

/** STRING PROTOTYPE ****************************** */

declare global {
  interface String {
    isEmpty(): boolean;
    isNotEmpty(): boolean;
    isNumber(): boolean;
    isStartWithNumber(): boolean;
    isEndWithNumber(): boolean;
    isValidA9(compareWith?: string): boolean;
    extractA9(): { A: string; N: string };
    toProperCase(): string;
    toSingleSpace(): string;
    inputDecimal(maxD): string;
    toFloat(): number;
    toBytes(): any[];
    replaceAll(find: string, replace: string): string;
    removeAll(find: string): string;
    removeLast(count: number);
    removeLastOf(subStr: string): string;
    format(...params: any[]): string;
    /** add T | E prefix */
    lang(): string;
  }
}

String.prototype.toProperCase = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

String.prototype.isEmpty = function (): boolean {
  return this == null || this == undefined || this.trim() == '';
};

String.prototype.isNotEmpty = function (): boolean {
  return !(this == null || this == undefined || this.trim() == '');
};

String.prototype.isNumber = function (): boolean {
  let value = this;

  return value != null && value !== '' && !isNaN(Number(value.toString()));
};

String.prototype.isStartWithNumber = function (): boolean {
  let str = this.trim();
  return str.length > 0 ? !isNaN(Number(str[0])) : false;
};

String.prototype.isEndWithNumber = function (): boolean {
  let str = this.trim();
  return str.length > 0 ? !isNaN(Number(str[str.length - 1])) : false;
};

/** format voucher AB001 - AB999
 * ถ้าไม่มี compareWith ก็แปลว่า ตรวจสอบรูปแบบเฉพาะอันแรก คือขึ้นต้นด้วย อักษร + ตัวเลข
 * ถ้ามี compareWith ก็แปลว่า ตรวจสอบรูปแบบอันที่สองว่าตรงกับอันแรกหรือไม่ คือต้องขึ้นต้น เหมือนกัน ลงท้ายตัวเลขจำนวนหลักเท่ากัน */
String.prototype.isValidA9 = function (compareWith?: string): boolean {
  let str = this.trim();
  if (!compareWith) {
    return str.length > 1 && !str.isStartWithNumber() && str.isEndWithNumber();
  } else {
    let runs = compareWith.match(/(\d+)/g);
    if (!runs) return false;

    let runDigits = runs[runs.length - 1].length;
    return compareWith.removeLast(runDigits) == str.removeLast(runDigits);
  }
};

String.prototype.extractA9 = function (): any {
  if (!this.isValidA9()) return undefined;

  let str = this.trim();
  let runs = str.match(/(\d+)/g);
  if (!runs) return undefined;

  let runDigits = runs[runs.length - 1].length;
  let A = str.removeLast(runDigits);

  return { A: A, N: runs[runs.length - 1] };
};

String.prototype.toSingleSpace = function (): string {
  let str = this;
  return str.isEmpty() ? str : str.trim().replace(/\s+/g, ' ');
};

String.prototype.inputDecimal = function (maxD): string {
  let str = this;

  str = str.trim();

  if (str == '') return str;
  if (str == '-') return str;
  if (str == '.') return '0.';

  str = str.removeAll(',');

  if (isNaN(str)) {
    str = str.substr(0, str.length - 1);
  }

  if (str.endsWith('.')) {
    return parseFloat(str).formatDecimal(0, 0) + '.';
  } else if (str.includes('.')) {
    const pointLen = str.split('.')[1].length;
    const minD = pointLen > maxD ? maxD : pointLen;
    return parseFloat(str).formatDecimal(minD, maxD);
  } else {
    return parseFloat(str).formatDecimal(0, maxD);
  }
};

String.prototype.toFloat = function (): number {
  let str = this;
  if (str.trim() == '') return 0;

  let res = parseFloat(str.removeAll(',').removeAll('%'));
  return isNaN(res) ? 0 : res;
};

String.prototype.toBytes = function (): any[] {
  var bytes = [];
  for (var i = 0; i < this.length; ++i) {
    bytes.push(this.charCodeAt(i));
  }
  return bytes;
};

String.prototype.replaceAll = function (find: string, replace: string): string {
  let str = this;
  return str.isEmpty() ? str : str.replace(new RegExp(find.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'g'), replace);
};

String.prototype.removeAll = function (find: string): string {
  let str = this;
  return str.isEmpty() ? str : str.replace(new RegExp(find.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1'), 'g'), '');
};

String.prototype.removeLast = function (count: number): string {
  return this.slice(0, -count);
};

String.prototype.removeLastOf = function (subStr: string): string {
  let str = this;
  let s = this.lastIndexOf(subStr);
  let len = subStr.length;

  return s == -1 ? str : str.slice(0, s - 1) + str.slice(s + len, str.length);
};

String.prototype.format = function (...params: any[]): string {
  let text = this;

  for (let i = 0; i < params.length; i++) {
    const e = params[i];
    let token = '${' + i + '}';
    text = text.replaceAll(token, e);
  }
  return text;
};

/** add T | E prefix */
String.prototype.lang = function (): string {
  let text = this;
  let prefix = i18n.language.charAt(0).toUpperCase();
  return prefix + text;
};
