import { Dashboard } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import { useNavigate } from 'react-router-dom';

export default function DashboardIcon() { 
  const navigate = useNavigate();
  const handleOnClick = () => navigate('/dashboard');

  return (
    <IconButton onClick={handleOnClick}>
      <Dashboard color="primary" sx={{ fontSize: 30 }} />
    </IconButton>
  );
}
