import "src/dashboard/Dashboard.css";

import * as signalR from "@microsoft/signalr";

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

import ImgInventory from "src/assets/images/dashboard/inventory-search.png";
import ImgNewCustomer from "src/assets/images/dashboard/new-customer.png";
import ImgThisMonth from "src/assets/images/dashboard/this-month-sale.png";
import ImgTotalCustomer from "src/assets/images/dashboard/total-customer.png";
import ImgTotalSale from "src/assets/images/dashboard/totalsale.png";
import ImgTruck from "src/assets/images/dashboard/truck.png";

import moment from "moment";

import { PxData } from "src/library/data-api";
import "src/utils/extensions";
import React, { useEffect, useRef, useState } from "react";

import { sql_mas_ct } from "src/globals/sql-script/dashboard-mas-ct.sql";
import { sql_sum_sls } from "src/globals/sql-script/dashboard-sum-sls.sql";
// import {store} from '@redux/store';
import { MONTH_SHORTNAME } from "src/globals/constants";
import { t } from "i18next";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function DashboardScreen({}) {
  const YMD = "YYYY-MM-DD";

  const [isFirstLoad_dataSourceMonthlySaleSummaryChart, setIsFirstLoad_dataSourceMonthlySaleSummaryChart] = useState(true);

  // info-box
  const [toDaySaleValue, setToDaySaleValue] = useState(0);
  const [toDaySaleTrans, setToDaySaleTrans] = useState(0);

  const [thisMonthSaleValue, setThisMonthSaleValue] = useState(0);
  const [thisMonthSaleTrans, setThisMonthSaleTrans] = useState(0);

  const [totalCustomer, setTotalCustomer] = useState(0);
  const [newCustomerOfMonth, setNewCustomerOfMonth] = useState(0);

  // chart monthly sale summary
  const [dataSourceMonthlySaleSummaryChart, setDataSourceMonthlySaleSummaryChart] = useState([]); // : MonthlySaleSummaryChartData[]
  const [monthlySaleSummaryYears, setMonthlySaleSummaryYears] = useState([]);

  // top five person
  const [topFivePersons, setTopFivePersons] = useState([]); // : TopFivePerson[]

  // today sale ranking
  const [toDaySaleRanking, setToDaySaleRanking] = useState([]); // : ToDaySaleRanking[]

  // best sales item
  const [bestSalesItems, setBestSalesItems] = useState([]); // : BestSalesItem[]
  const [bestSalesItemsOption, setBestSalesItemsOption] = useState(0);
  const [bestSalesItems_weeklyDataLoading, setBestSalesItems_weeklyDataLoading] = useState(false);
  const [bestSalesItems_monthlyDataLoading, setBestSalesItems_monthlyDataLoading] = useState(false);

  const dbID = localStorage.getItem("dbid");

  useEffect(() => {
    PxData.default = new PxData();

    hubStore();
    fetchSUM_SLS();
  }, []);

  function hubStore() {
    const hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(PxData.default.endPoint.signalR_URL, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
      })
      .withAutomaticReconnect()
      // .configureLogging(signalR.LogLevel.Information)
      .configureLogging(signalR.LogLevel.None)
      .build();

    // กรณี server-down/restart จะต้องทำการ RegisterNotifyDataChanged ใหม่ทุกครั้ง เพื่อให้ทำงานต่อได้
    hubConnection.onreconnected((connectionId) => {
      hubConnection.invoke("RegisterNotifyDataChanged", dbID, "mas_ct,sum_sls").catch((error) => console.error(error));
      console.log("RegisterNotifyDataChanged .. onreconnected");
    });

    // starting the connection
    hubConnection
      .start()
      .then(() => {
        console.log("HUB Connection started");
        console.log(hubConnection.state);

        if (hubConnection.state == signalR.HubConnectionState.Connected) {
          //xx
          // hubConnection.invoke('RegisterNotifyDataChanged', store.getState().login.databaseID, 'mas_ct,sum_sls').catch(error => console.error(error));
          hubConnection.invoke("RegisterNotifyDataChanged", dbID, "mas_ct,sum_sls").catch((error) => console.error(error));
        }
      })
      .catch((error) => console.log("Error while starting connection: " + error));

    // data coming from the server
    hubConnection.on("notifyDataChanged", (databaseID, notifyFor, response) => {
      proceedDataChanged();
    });
  }

  /** keep last update of data */
  const lastchanged_sum_sls = useRef<moment.Moment>();
  const lastchanged_mas_ct = useRef<moment.Moment>();

  function proceedDataChanged() {
    const sql = ` SELECT * FROM ChangeNotify where TableName IN ('SUM_SLS','MAS_CT') `;
    PxData.default.getDataSet(sql).then((res) => {
      const table = res.tables[0];
      const keys = Object.keys(table);
      table.map((row) => {
        if (row["TableName"] == "SUM_SLS" && moment(row["LastUpdate"]).isAfter(lastchanged_sum_sls.current ?? moment("2000-01-01", YMD))) {
          lastchanged_sum_sls.current = moment(row["LastUpdate"]);
          fetchSUM_SLS();
        }
        if (row["TableName"] == "MAS_CT" && moment(row["LastUpdate"]).isAfter(lastchanged_mas_ct.current ?? moment("2000-01-01", YMD))) {
          lastchanged_mas_ct.current = moment(row["LastUpdate"]);
          fetchMAS_CT();
        }
      });
    });
  }

  function fetchMAS_CT() {
    PxData.default.getDataSet(sql_mas_ct).then((res) => {
      console.log("data loaded .. MAS_CT");

      let _data_total_customer = res.tables[0];
      let _data_new_customer_of_month = res.tables[1];

      if (Object.keys(_data_total_customer).length == 0) return;

      let row = _data_total_customer[0];
      setTotalCustomer(row["TotalCustomer"]);

      row = _data_new_customer_of_month[0];
      setNewCustomerOfMonth(row["NewCustomerOfMonth"]);
    });
  }

  function fetchSUM_SLS() {
    var sql = sql_sum_sls;

    sql = sql.replace("#bestSalesItemsOption", bestSalesItemsOption.toString());

    // Info Box of top dashboard

    PxData.default.getDataSet(sql.replace("#queryFor", "infoBox_monthlySalesSummary")).then((res) => {
      console.log("data loaded .. SUM_SLS (Info-box & Monthly Sales Summary)");

      let table0 = res.tables[0];
      let table1 = res.tables[1];

      if (Object.keys(table0).length == 0) {
        return;
      }

      setToDaySaleValue(0);
      setToDaySaleTrans(0);

      setThisMonthSaleValue(0);
      setThisMonthSaleTrans(0);

      let row = table0.find((row) => {
        return moment(row["ADate"]).format(YMD) === moment().format(YMD);
      });
      if (row != null) {
        setToDaySaleValue(row["Net"]);
        setToDaySaleTrans(row["Trn"]);
      }

      let yy = moment().year();
      let mm = moment().month() + 1;

      row = table1.find((row) => {
        return row["AYear"] == yy && row["AMonth"] == mm;
      });
      if (row != null) {
        setThisMonthSaleValue(row["Net"] ?? 0);
        setThisMonthSaleTrans(row["Trn"] ?? 0);
      }

      // Monthly Sales Summary Chart
      setDataSourceMonthlySaleSummaryChart(getDataSourceMonthlySaleSummaryChart(table1));
    });

    // Top Five Person

    PxData.default.getDataSet(sql.replace("#queryFor", "topFivePerson")).then((res) => {
      console.log("data loaded .. SUM_SLS (Top Five Person)");

      let table = res.tables[0];
      if (Object.keys(table).length == 0) {
        return;
      }

      const _topFivePersons = [];
      table.map((row) => {
        // safeHTML มาตรฐานความปลอดภัย ไม่งั้นจะ  WARNING: sanitizing unsafe URL value data:image/jpeg;base64,  (see http://g.co/ng/security#xss)
        // let picture = (row['Picture']) ? sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + <string>row['Picture']) : null
        _topFivePersons.push({ no: row["No"], name: row["STName"], fullName: row["FullName"], branch: row["BHName"], net: row["Net"], picture: null });
      });
      // console.log(_topFivePersons);
      setTopFivePersons(_topFivePersons);
    });

    // Today Sale Ranking

    PxData.default.getDataSet(sql.replace("#queryFor", "todaySalesRanking")).then((res) => {
      console.log("data loaded .. SUM_SLS (Today Sales Ranking)");

      let table = res.tables[0];
      if (Object.keys(table).length == 0) {
        return;
      }

      const sumNet = table.reduce((sum, row) => {
        return sum + row["Net"];
      }, 0);

      const _toDaySaleRanking = table.map((row) => {
        let percent = (row["Net"] / sumNet) * 100;
        return { no: row["No"], name: row["BHName"], net: row["Net"], trn: row["Trn"], percent: percent };
      });

      // _toDaySaleRanking.push(..._toDaySaleRanking, ..._toDaySaleRanking, ..._toDaySaleRanking, ..._toDaySaleRanking)

      setToDaySaleRanking(_toDaySaleRanking);
    });

    // Best Sales Items

    PxData.default.getDataSet(sql.replace("#queryFor", "bestSalesItems")).then((res) => {
      console.log("data loaded .. SUM_SLS (Best Sales Items)");

      let table = res.tables[0];
      if (Object.keys(table).length == 0) {
        return;
      }

      const _bestSalesItems = [];
      table.map((row) => {
        // safeHTML มาตรฐานความปลอดภัย ไม่งั้นจะ  WARNING: sanitizing unsafe URL value data:image/jpeg;base64,  (see http://g.co/ng/security#xss)
        // let picture = (row['Picture']) ? sanitizer.bypassSecurityTrustUrl("data:Image/*;base64," + <string>row['Picture']) : null
        _bestSalesItems.push({ no: row["No"], mpcode: row["MPCode"], mpname: row["MPName"], listPrice: row["ListPrice"], qty: row["Qty"], net: row["Net"], picture: row["Picture"] });
      });

      setBestSalesItems(_bestSalesItems);
      setBestSalesItems_monthlyDataLoading(false);
      setBestSalesItems_weeklyDataLoading(false);
    });
  }

  function getDataSourceMonthlySaleSummaryChart(data) {
    // get unique year that return from query
    const distinct = (value: any, index: any, self: any) => {
      return self.indexOf(value) === index;
    };
    const _uniqueYears = data
      .map((row) => {
        return row["AYear"];
      }) // Get Only Field [AYear]
      .filter(distinct);

    // state var use in ui
    setMonthlySaleSummaryYears(_uniqueYears);

    let result = [];

    data.forEach((row) => {
      const aYear = row["AYear"];
      const aMonth = row["AMonth"];
      const net = row["Net"];

      // find row by month, year
      let index = result.findIndex((item) => item.month == aMonth);

      if (index != -1) {
        // update

        let item = result[index];
        if (aYear == _uniqueYears[0]) {
          item.lastYear = net;
        } else if (aYear == _uniqueYears[1]) {
          item.thisYear = net;
        }
      } else {
        // insert

        let monthName = MONTH_SHORTNAME["th"][aMonth - 1];
        let lastYear = aYear == _uniqueYears[0] ? net : null;
        let thisYear = aYear == _uniqueYears[1] ? net : null;
        result.push({ month: aMonth, monthName: monthName, lastYear: lastYear, thisYear: thisYear });
      }
    });

    return result;
  }

  // *render* \\

  return (
    <div style={{ margin: "auto", alignItems: "center", justifyContent: "center" }}>
      <div style={{ display: "flex", flex: 1, flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", margin: 20, marginTop: 0 }}>
        <div style={{ flex: 1, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <InfoBox backgroundColor="#F77E21" imageSource={ImgTotalSale} title={"฿" + toDaySaleValue.formatDecimal(2, 2)} value={t("TodaySale", { value: toDaySaleTrans })}></InfoBox>
          <InfoBox backgroundColor="#F47C7C" imageSource={ImgThisMonth} title={"฿" + thisMonthSaleValue.formatDecimal(2, 2)} value={t("ThisMonthSale", { value: thisMonthSaleTrans })}></InfoBox>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          <InfoBox backgroundColor="#4B7BE5" imageSource={ImgNewCustomer} title={newCustomerOfMonth.formatDecimal(0)} value={t("NewCustomerOfMonth")}></InfoBox>
          <InfoBox backgroundColor="#6A67CE" imageSource={ImgTotalCustomer} title={totalCustomer.formatDecimal(0)} value={t("TotalCustomer")}></InfoBox>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", margin: 20 }}>
        {/* Today Sales By Store Ranking */}
        <div className="contentBlock">
          <TodaySaleByStore data={toDaySaleRanking} />
        </div>

        <div className="contentBlock">
          <TopFivePerson data={topFivePersons} />
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", margin: 20 }}>
        <div className="contentBlock">
          <MonthlySaleSummaryChart data={dataSourceMonthlySaleSummaryChart} years={monthlySaleSummaryYears} />
        </div>
        {/* Best Sales Items (Monthly,Weekly) */}
        <div className="contentBlock">
          <BestSaleItems data={bestSalesItems} />
        </div>
      </div>
    </div>
  );
}

function InfoBox({ backgroundColor, imageSource, title, value }) {
  return (
    <div className="infoBox boxShadow" style={{ backgroundColor: backgroundColor }}>
      <div>
        <img src={imageSource} width="48" height="48" />
      </div>
      <div style={{}}>
        <h2 className="infoTitle">{title}</h2>
        <p className="infoValue">{value}</p>
      </div>
    </div>
  );
}

function TodaySaleByStore({ data }) {
  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 10 }}>
        <div>
          <p style={{ color: "black", fontWeight: "bold" }}>Today Sales By Store Ranking</p>
        </div>

        <table className="styled-table" style={{ width: "100%" }}>
          <thead>
            <tr className="box" id="g4">
              <th>#</th>
              <th>สาขา</th>
              <th style={{ textAlign: "right" }}>ยอดขาย</th>
              <th style={{ textAlign: "right" }}>จำนวนบิล</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.no}</td>
                  <td>{item.name}</td>
                  <td style={{ textAlign: "right" }}>{(item.net ?? 0).formatDecimal(2, 2)}</td>
                  <td style={{ textAlign: "right" }}>{(item.trn ?? 0).formatDecimal(0, 0)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div style={{ height: 20 }}></div>
      </div>
    </div>
  );
}

function BestSaleItems({ data }) {
  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 10 }}>
        <div>
          <p style={{ color: "black", fontWeight: "bold" }}>Best Sales Items</p>
        </div>

        <table className="styled-table" style={{ width: "100%" }}>
          <thead>
            <tr className="box" id="g1">
              <th>#</th>
              <th>สินค้า</th>
              <th style={{ textAlign: "right" }}>ราคา</th>
              <th style={{ textAlign: "right" }}>จำนวน</th>
              <th style={{ textAlign: "right" }}>จำนวนเงิน</th>
              <th style={{ textAlign: "right" }}></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.no}</td>
                  <td style={{ fontSize: 12 }}>{item.mpname}</td>
                  <td style={{ textAlign: "right" }}>{(item.listPrice ?? 0).formatDecimal(2, 2)}</td>
                  <td style={{ textAlign: "right" }}>{(item.qty ?? 0).formatDecimal(0, 0)}</td>
                  <td style={{ textAlign: "right" }}>{(item.net ?? 0).formatDecimal(2, 2)}</td>
                  <td style={{ textAlign: "right" }}>
                    <img src={`data:image/jpeg;base64,${item.picture}`} width="48" height="48" className="boxShadow" style={{ borderRadius: "50%" }} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div style={{ height: 20 }}></div>
      </div>
    </div>
  );
}

function TopFivePerson({ data }) {
  useEffect(() => {
    // console.log(data)
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <div style={{ flex: 1, paddingLeft: 10, paddingRight: 10, borderRadius: 10 }}>
        <div>
          <p style={{ color: "black", fontWeight: "bold" }}>Top Five Person of Month</p>
        </div>

        <table className="styled-table" style={{ width: "100%" }}>
          <thead>
            <tr className="box" id="g3">
              <th>#</th>
              <th>พนักงาน</th>
              <th>สาขา</th>
              <th style={{ textAlign: "right" }}>ยอดขาย</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.no}</td>
                  <td>{item.fullName}</td>
                  <td>{item.branch}</td>
                  <td style={{ textAlign: "right" }}>{item.net.formatDecimal(2, 2)}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div style={{ height: 20 }}></div>
      </div>
    </div>
  );
}

function MonthlySaleSummaryChart({ data, years }) {
  useEffect(() => {
    createBarData();
    // console.log(data);
  }, [data]);

  const [barData, setBarData] = useState<any>();

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top" as const,
      },
      title: {
        display: true,
        text: "Monthly Sale Summary",
        fontSize: 16,
      },
    },
  };

  function createBarData() {
    const labels = data.map((i) => i.monthName);

    const _data = {
      labels,
      datasets: [
        {
          label: years[0],
          data: data.map((i) => i.lastYear / 1000),
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        {
          label: years[1],
          data: data.map((i) => i.thisYear / 1000),
          backgroundColor: "rgba(53, 162, 235, 0.5)",
        },
      ],
    };

    setBarData(_data);
  }

  return <>{barData && <Bar options={options} data={barData} />}</>;
}
