/**
 * This file contains feed data from WEB API and implement
 * query, getDataSet, PxDataRow PxDataTable, PxDataSet and ...
 */

import { API_URL_HOST, API_URL_HOST_LOCAL, SIGNAL_R_URL_HOST, SIGNAL_R_URL_HOST_LOCAL } from "src/globals/constants";
import cloneDeep from "clone-deep";

import { PxConvert, PxUniqueId, PxUtils, alertMessage } from "src/utils/utils";

import { translate } from "src/i18n/i18n";

// import { store } from '@redux/store';
import axios from "axios";
import moment from "moment";
import pako from "pako";

export type DataResult = [string, any];
export type DataResults = [DataResult];

export type CallbackBooleaneanError = (response: boolean, error: any) => void;
export type CallbackStringError = (response: string, error: any) => void;

export type CallbackSqlQueryResponse = (response: SqlDataResponse) => void;

export type CallbackDataResultError = (response: DataResult, error: any) => void;
export type CallBackDataResultsError = (response: DataResult[], error: any) => void;

export type CallbackError = (error: any) => void;

export type CallbackDataResult = (response: DataResult) => void;
export type CallBackDataResults = (response: DataResult[]) => void;

export type SqlWithDataTable = {
  sql: string;
  dataTable: PxDataTable;
  tag?: any;
};
export type OnTableStateChanged = () => void;
export type OnRowStateChanged = (rowState: RowState) => void;

interface SqlQueryRequestWithDbID {
  databaseID: string;
  querystring: string;
  encryption: number;
}

export enum RowState {
  added,
  modified,
  deleted,
  detached,
  unchanged,
}

export enum PxSqlDataType {
  image = 34,
  text = 35,
  uniqueidentifier = 36,
  date = 40,
  time = 41,
  datetime2 = 42,
  datetimeoffset = 43,
  tinyint = 48,
  smallint = 52,
  int = 56,
  smalldatetime = 58,
  real = 59,
  money = 60,
  datetime = 61,
  double = 62,
  sql_variant = 98,
  ntext = 99,
  bit = 104,
  decimal = 106,
  numeric = 108,
  smallmoney = 122,
  bigint = 127,
  varbinary = 165,
  varchar = 167,
  binary = 173,
  char = 175,
  timestamp = 189,
  nvarchar = 231,
  nchar = 239,
  xml = 241,
  unknown = 0,
}

export class SqlDataResponse {
  dataSet: DataResults;
  tables: DataResult[] = [];

  errorMessage: string;
  querystring: string;

  get hasError() {
    return this.errorMessage != null;
  }
  get hasTable() {
    return Object.keys(this.dataSet).length;
  }
  get firstTable() {
    return this.tables[0];
  }

  constructor(res?: [string, any]) {
    if (res) {
      this.dataSet = res["DataSet"];
      this.errorMessage = res["ErrorMessage"];
      this.querystring = res["QueryString"];

      if (this.dataSet) {
        let tableNames = Object.keys(this.dataSet);
        tableNames.map((e) => {
          this.tables.push(this.dataSet[e]);
        });
      }
    }
  }

  showError() {
    console.log(this.errorMessage);
    //xx
    // showMessage({
    //   message: this.errorMessage,
    //   description: this.querystring,
    //   autoHide: false,
    //   type: 'danger',
    // });
  }
}

export class SqlValueResponse {
  value: any;
  errorMessage: string;
  querystring: string;

  get hasError() {
    return this.errorMessage != null;
  }

  constructor(res?: [string, any]) {
    if (res) {
      this.value = res["Value"];
      this.errorMessage = res["ErrorMessage"];
      this.querystring = res["QueryString"];
    }
  }

  showError() {
    console.log(this.errorMessage);
    //xx
    //   showMessage({
    //     message: this.errorMessage,
    //     description: this.querystring,
    //     autoHide: false,
    //     type: 'danger',
    //   });
  }
}

export class EndPoints {
  databaseID = "";
  apiURL = "";
  signalR_URL = SIGNAL_R_URL_HOST;
  // signalR_URL =  SIGNAL_R_URL_HOST_LOCAL;

  // constructor(databaseID: string, apiURL: string = API_URL_HOST_LOCAL) {
  constructor(databaseID: string, apiURL: string = API_URL_HOST) {
    this.databaseID = databaseID;
    this.apiURL = apiURL;
  }
}

export class PxDataColumn {
  // QUERY DATATYPE INFO
  name: string = "";
  dataType = PxSqlDataType.unknown;
  isNullable: boolean;
  maxLength: number; // CHAR MAX LENGTH
  precision: number; // NUMERIC PRECISION
  scale: number; // NUMERIC SCALE

  get isNumeric() {
    return [PxSqlDataType.uniqueidentifier, PxSqlDataType.tinyint, PxSqlDataType.smallint, PxSqlDataType.int, PxSqlDataType.real, PxSqlDataType.money, PxSqlDataType.double, PxSqlDataType.decimal, PxSqlDataType.numeric, PxSqlDataType.smallmoney, PxSqlDataType.bigint].includes(
      this.dataType
    );
  }
  get isDateTime() {
    return [PxSqlDataType.date, PxSqlDataType.datetime2, PxSqlDataType.datetimeoffset, PxSqlDataType.time, PxSqlDataType.smalldatetime, PxSqlDataType.datetime].includes(this.dataType);
  }
  get isString() {
    return [PxSqlDataType.text, PxSqlDataType.ntext, PxSqlDataType.varchar, PxSqlDataType.char, PxSqlDataType.nvarchar, PxSqlDataType.nchar].includes(this.dataType);
  }
  get isBoolean() {
    return this.dataType == PxSqlDataType.bit;
  }

  //  SOURCE TABLE INFO
  sourceTable: string;
  sourceColumn: string;

  isBoundingColumn: boolean; // FOR SAVE BACK TO TABLE .. IS BOUNDING COLUMN
  isIdentityColumn: boolean; // AUTO ID COLUMN
  isPartOfUniqueKey: boolean; // PART OF PRIMARY KEY
  isUpdateable: boolean; // READ ONLY COLUMN
  isComputedColumn: boolean; // COMPUTED COLUMN

  get isReadOnly() {
    return this.isUpdateable;
  }
  set isReadOnly(value) {
    this.isUpdateable = !value;
  }

  // EXTENT PROPERTIES
  defaultValue: any;
  caption: string;

  constructor(
    name: string,
    dataType: PxSqlDataType,
    isNullable?: boolean,
    maxLength?: number,
    precision?: number,
    scale?: number,
    sourceTable?: string,
    sourceColumn?: string,
    isBoundingColumn?: boolean,
    isIdentityColumn?: boolean,
    isPartOfUniqueKey?: boolean,
    isUpdateable?: boolean,
    isComputedColumn?: boolean
  ) {
    this.name = name;
    this.dataType = dataType;
    this.isNullable = isNullable!;
    this.maxLength = maxLength!;
    this.precision = precision!;
    this.scale = scale!;

    this.sourceTable = sourceTable!;
    this.sourceColumn = sourceColumn!;
    this.isBoundingColumn = isBoundingColumn!;
    this.isIdentityColumn = isIdentityColumn!;
    this.isPartOfUniqueKey = isPartOfUniqueKey!;
    this.isUpdateable = isUpdateable!;
    this.isComputedColumn = isComputedColumn!;
  }
}

export class PxData {
  /*  WALK THROUGH DataResult 

  for(let t of response['DataSet']['Table']) {
    console.log(t["Description"].tostring());
  }
  */
  static default: PxData;

  endPoint = new EndPoints(localStorage.getItem("dbid"));

  constructor(databaseID = null) {
    if (databaseID) this.endPoint = new EndPoints(databaseID);
  }

  getDataSet(sql: string, showError: boolean = true, endPoint: EndPoints = this.endPoint): Promise<SqlDataResponse> {
    return <Promise<SqlDataResponse>>this.query("GetDataSet", sql, showError, endPoint);
  }
  execNonQuery(sql: string, showError: boolean = true, endPoint: EndPoints = this.endPoint): Promise<SqlDataResponse> {
    return <Promise<SqlDataResponse>>this.query("ExecNonQuery", sql, showError, endPoint);
  }
  execScalar(sql: string, showError: boolean = true, endPoint: EndPoints = this.endPoint): Promise<SqlValueResponse> {
    return <Promise<SqlValueResponse>>this.query("ExecScalar", sql, showError, endPoint);
  }
  query(actionName: string, sql: string, showError: boolean = true, endPoint: EndPoints = this.endPoint): Promise<unknown> {
    sql = PxConvert.arrayBufferToBase64(pako.gzip(sql, null)); // sql compressed with gzip
    sql = PxUtils.encodeUrlWebApiParamData(sql); // sql encode '/' = (slash) .. + =

    // GET method
    // let url = this.model.apiURL + '/iGetDataSet/' + this.model.dbId + '/' + sql + '/2';

    // POST method
    let url = endPoint.apiURL + "/" + actionName;
    let sqlQueryRequestWithDBID: SqlQueryRequestWithDbID = {
      databaseID: endPoint.databaseID,
      querystring: sql,
      encryption: 2,
    };

    // const https = require('https');

    //  const httpsAgent = new https.Agent({
    //   maxVersion: "TLSv1.2",
    //   minVersion: "TLSv1.2"
    // });

    return new Promise((resolve, reject) => {
      // fetch(url, {
      //   method: 'POST',
      //   headers: {
      //     Accept: 'application/json',
      //     'Content-Type': 'application/json',
      //   },
      //   body: JSON.stringify(sqlQueryRequestWithDBID),
      // })
      // .then((res) => res.json())

      axios
        .post(url, sqlQueryRequestWithDBID, {
          // httpsAgent : httpsAgent,
          // withCredentials: true,
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json; charset=utf-8",
          },
        })
        .then((response) => {
          // for axios
          let res: any = actionName == "ExecScalar" ? new SqlValueResponse(response.data) : new SqlDataResponse(response.data);

          // for fetch
          // let res: any = actionName == 'ExecScalar' ? new SqlValueResponse(response) : new SqlDataResponse(response);

          if (res.hasError) {
            if (res.errorMessage.includes("The DELETE statement conflicted with the REFERENCE constraint")) {
              alertMessage({
                title: translate("Unsuccess"),
                message: translate("msg_CannotDelete"),
              });
            } else if (showError) {
              res.showError();
            }
            reject(res.errorMessage);
          }

          // success
          else {
            resolve(res);
          }
        })
        .catch((error) => {
          console.log("x cccc");
          console.error("..#..");
          console.error(error.response);

          reject(error);
        });
    });
  }
}

export class PxSQL {
  static andCriteria_BH_Id_TMCode(bh_id, tmcode, columnAlias = "") {
    return ` ${columnAlias}BH_Id = ${bh_id} AND ${columnAlias}TMCode = '${tmcode}' `;
  }

  static andCriteria_BH_Id_AbbDate(bh_id, abbdate: Date, columnAlias = "") {
    return ` ${columnAlias}BH_Id = ${bh_id} AND ${columnAlias}AbbDate = ${PxSQL.toSqlDate(new Date(abbdate))} `;
  }

  static andCriteria_BH_Id_TMCode_AbbNo(bh_id, tmcode, abbno, columnAlias = "") {
    return ` ${columnAlias}BH_Id = ${bh_id} AND ${columnAlias}TMCode = '${tmcode}' AND ${columnAlias}AbbNo = '${abbno}' `;
  }

  static andCriteria_BH_Id_TMCode_AbbDate(bh_id, tmcode, abbdate: Date, columnAlias = "") {
    return ` ${columnAlias}BH_Id = ${bh_id} AND ${columnAlias}TMCode = '${tmcode}' AND ${columnAlias}AbbDate = ${PxSQL.toSqlDate(new Date(abbdate))} `;
  }

  static andCriteria_BH_Id_TMCode_AbbNo_AbbDate(bh_id, tmcode, abbno, abbdate: Date, columnAlias = "") {
    return ` ${columnAlias}BH_Id = ${bh_id} AND ${columnAlias}TMCode = '${tmcode}' AND ${columnAlias}AbbNo = '${abbno}' AND ${columnAlias}AbbDate = ${PxSQL.toSqlDate(new Date(abbdate))} `;
  }

  static createAndCriteria(...params: any[]): string {
    let result = "";
    for (let i = 0; i < params.length; i++) {
      result += (result != "" ? " AND " : "") + params[i];
    }
    return result;
  }

  static sqlForNewId(tableName, bh_id, intSize = 32) {
    // like @MAS_ST$Id

    let id = `@${tableName}$Id`;

    return `
    DECLARE ${id} bigint
    EXEC sp_GetNewId${intSize} '${tableName}', ${bh_id}, ${id} OUTPUT
      `;
  }
  static sqlForSavePicture_MAS_XX_PX(tableName, fieldName, id, picture) {
    return `
    IF NOT EXISTS(SELECT 1 FROM ${tableName} WHERE ${fieldName} = ${id} AND PxNo = 1)
    BEGIN
    INSERT ${tableName} (${fieldName}, PxNo, Picture) VALUES(${id}, 1, ${PxSQL.toVarBinaryMax(picture)})
    END ELSE BEGIN
    UPDATE ${tableName} SET Picture = ${PxSQL.toVarBinaryMax(picture)} WHERE ${fieldName} = ${id} AND PxNo = 1
    END`;
  }
  static sqlForGetBC() {
    let priceNList = "";
    let n = 10;
    for (let i = 1; i <= n; i++) {
      priceNList += (priceNList == "" ? "" : ",") + "BC.Price" + i.toString();
    }
    const TE = translate("TE");
    return `
      (SELECT BC.Id, BC.BCCode, BC.MP_Id, ${priceNList}, BC.DPrice, BC.UM_Id, UM.${TE}LUName UMName, BC.UMRatio, BC.Selling 
         FROM MAS_MP_BC BC LEFT OUTER JOIN MAS_LU UM ON BC.UM_Id = UM.Id)`;
  }

  static sqlForInTransactionBlock(sql) {
    return `
    BEGIN TRANSACTION
    BEGIN TRY

    ${sql}

    COMMIT TRANSACTION

    END TRY BEGIN CATCH

    SELECT  ERROR_NUMBER() AS ErrorNumber, ERROR_SEVERITY() AS ErrorSeverity,
      ERROR_STATE() AS ErrorState, ERROR_PROCEDURE() AS ErrorProcedure,
        ERROR_LINE() AS ErrorLine, ERROR_MESSAGE() AS ErrorMessage;

    DECLARE @ErrorNumber    INT = ERROR_NUMBER()
    DECLARE @ErrorMessage   NVARCHAR(4000) = ERROR_MESSAGE()
    DECLARE @ErrorProcedure NVARCHAR(4000) = ERROR_PROCEDURE()
    DECLARE @ErrorLine      INT = ERROR_LINE()
    RAISERROR('An error occurred within a user transaction.  
              Error Number        : % d                 
              Error Message       : % s 
              Affected Procedure  : % s 
              Affected Line Number: % d', 16, 1, @ErrorNumber, @ErrorMessage, @ErrorProcedure,@ErrorLine) 

          IF  @@TRANCOUNT > 0
    ROLLBACK TRANSACTION

    END CATCH`;
  }

  static sqlFor_ColumnStructure(tableName: string, withPrimaryKey: boolean, with_CreateDt_UpdateDt: boolean = false): Promise<string> {
    let sql = `EXEC dbo.sp_GetColumnStructure '${PxSQL.toValues(tableName, withPrimaryKey, with_CreateDt_UpdateDt)}`;
    return new Promise((resolve, reject) => {
      PxData.default
        .execScalar(sql)
        .then((res) => {
          resolve(res.value);
        })
        .catch((errMsg) => {
          resolve("");
        });
    });
  }
  static async sqlFor_DECLARE_TABLE_From_DB(tableName: string, withPrimaryKey: boolean, with_CreateDt_UpdateDt: boolean = false): Promise<string> {
    return `DECLARE ${tableName} TABLE (${await this.sqlFor_ColumnStructure(tableName, withPrimaryKey, with_CreateDt_UpdateDt)})\n`;
  }

  static sqlFor_DECLARE_TABLE_From_DataTable(table: PxDataTable, tableName: string, withPrimaryKey: boolean): string {
    return `DECLARE ${tableName} TABLE (${table.sqlScriptForColumnStructure(withPrimaryKey)})\n`;
  }

  static sqlFor_INSERT_VALUES(table: PxDataTable, tableName: string): string {
    let sql = "";

    table.rows.map((row) => {
      let insert = `INSERT ${tableName} VALUES(`;
      let values: any[] = [];
      table.columns.map((col) => {
        if (!col.isReadOnly) values.push(PxSQL.toValue(row.getFieldValue(col.name)));
      });
      sql += insert + " " + values.join(",") + ")\n";
    });

    return sql;
  }

  static toSqlDate(value) {
    if (!value) return "NULL";

    if (Object.prototype.toString.call(value) == "[object Date]") {
      return `'${moment(value).format("YYYY.MM.DD")}'`;
    } else if (value instanceof moment) {
      return `'${moment(value).format("YYYY.MM.DD")}'`;
    } else {
      return `'${moment(value).format("YYYY.MM.DD")}'`;
    }

    // else if (Object.prototype.toString.call(value) == '[object Date]') {
    //   value = new Date(value);
    //   return `'${moment().format('YYYY.MM.DD')}'`;
    //     "'" +
    //     value.getFullYear() +
    //     '.' +
    //     (value.getMonth() + 1) +
    //     '.' +
    //     value.getDate() +
    //     "'"
    //   );
    // }
  }

  static toSqlDateTime(value: Date) {
    if (!value) return "NULL";

    value = new Date(value);
    return "'" + value.getFullYear() + "." + (value.getMonth() + 1) + "." + value.getDate() + " " + value.getHours() + ":" + value.getMinutes() + ":" + value.getSeconds() + "." + value.getMilliseconds() + "'";
  }

  static toSqlTime(value: Date) {
    if (!value) return "NULL";

    value = new Date(value);
    return "'" + value.getHours() + ":" + value.getMinutes() + ":" + value.getSeconds() + "." + value.getMilliseconds() + "'";
  }

  // Image Column
  static toVarBinaryMax(value: any) {
    if (!value) return "NULL";

    value = PxConvert.base64ToHexString(value);
    return "CONVERT(VARBINARY(max), '" + value + "', 1)";
  }

  static toValue(value: any): string {
    if (value == null || value == undefined) {
      // is Null or Undefined
      return "NULL";
    } else if (typeof value == "number") {
      return value.toString();
    } else if (typeof value == "string") {
      // SQL TOKEN VARIABLE such as @MAS_ST$Id
      if (value.startsWith("@") && value.includes("$")) return value;
      else return "'" + value.toString().trim().replace(new RegExp("'", "g"), "''") + "'";
    } else if (typeof value == "boolean") {
      return "'" + value.toString() + "'";
    } else if (Object.prototype.toString.call(value) == "[object Date]" || value instanceof moment) {
      return PxSQL.toSqlDate(value);
    } else return "NULL";
  }

  static toValues(...params: any[]): string {
    let sql = "";
    for (let i = 0; i < params.length; i++) {
      sql += (sql != "" ? "," : "") + PxSQL.toValue(params[i]);
    }

    return sql;
  }

  // public static string ToValues(DataRow row)
  // {
  //     return ToValues(row, null);
  // }
  // public static string ToValues(DataRow row, string[] excludeColumns)
  // {
  //     string sql = "";
  //     foreach (DataColumn col in row.Table.Columns)
  //     {
  //         if (excludeColumns != null && Array.IndexOf(excludeColumns, col.ColumnName) != -1)
  //             continue;

  //         sql += (sql != "" ? "," : "") + ToValues(row[col]);
  //     }
  //     return sql;
  // }
}

export class PxDataSet {
  tables: PxDataTable[] = new Array();

  pxData: PxData;

  constructor(pxData: PxData = PxData.default) {
    this.pxData = pxData;
  }

  tableByName(name: string): PxDataTable {
    return this.tables.filter((t) => {
      return t.tableName == name;
    })[0];
  }

  query(sqls: SqlWithDataTable[], fillSchema: boolean, showProgress: boolean = false, showError: boolean = true): Promise<PxDataSet> {
    let command = "";

    sqls.forEach((e) => {
      command += e.sql + " SELECT * FROM sys.dm_exec_describe_first_result_set('" + e.sql.replace(new RegExp("'", "g"), "''") + "', NULL, 1) ";
    });

    return new Promise((resolve, reject) => {
      this.pxData
        .getDataSet(command, showError)
        .then((res) => {
          let dataset = res.dataSet;
          let n = 0;
          let i = 0;
          let count = Object.keys(res.dataSet).length; // JSON (DataResults อ่านค่า length ไม่ได้ต้องใช้ Object.keys มาช่วย)
          while (i < count) {
            let keyData = i == 0 ? "Table" : "Table" + i;
            let keySchema = "Table" + (i + 1);

            let tableData = dataset[keyData];
            let tableSchema = dataset[keySchema];

            let table = sqls[n].dataTable;
            n += 1;

            table.fillData(tableData);

            if (fillSchema) {
              let success = table.fillSchema(tableSchema);
              if (!success) {
                reject("fillSchema Error!");
                return;
              }
            }

            this.tables.push(table);

            i = i + 2;
          }

          resolve(this);
        })
        .catch((errMsg) => {
          reject(errMsg);
        });
    });
  }
}

export class PxDataTable {
  // func copy(with zone: NSZone? = nil) -> Any {
  //     let copy = PxDataTable()
  //     copy.tableName = this.tableName
  //     copy.columns = this.columns.map { $0 }
  //     copy.primaryKeys = this.primaryKeys.map { $0 }
  //     copy.rows = this.rows.map { $0 }
  //     return copy
  // }

  tableName: string;
  sql: string;
  sqlTableAlias: string;

  onTableStateChanged: OnTableStateChanged;

  // FOR CHECK UPDATE FROM SignalR
  changeNotifyLastUpdate: Date = new Date();

  columns: PxDataColumn[] = [];

  copy() {
    return cloneDeep(this);
  }

  hasColumn(name: string) {
    return this.columns.findIndex((c) => c.name.toLowerCase() == name.toLowerCase()) != -1;
  }
  columnByName(name: string): PxDataColumn | undefined {
    let cols = this.columns.filter((col) => {
      return col.name.toLowerCase() == name.toLowerCase();
    });
    let onlyBoundingColumn = cols.length > 1;

    let column = onlyBoundingColumn
      ? this.columns.find((col) => {
          return col.name.toLowerCase() == name.toLowerCase() && col.isBoundingColumn;
        })
      : this.columns.find((col) => {
          return col.name.toLowerCase() == name.toLowerCase();
        });

    if (column) {
      return column;
    } else {
      return undefined;
    }
  }
  columnIndex(name: string): number {
    return this.columns.findIndex((col) => {
      return col.name.toLowerCase() == name.toLowerCase();
    });
  }

  rows: PxDataRow[] = [];

  get isEmpty() {
    return this.rows.length == 0;
  }
  get isDataChanged() {
    return (
      this.rows.filter((row) => {
        return row.rowState == RowState.added || row.rowState == RowState.modified || row.rowState == RowState.deleted;
      }).length > 0
    );
  }

  acceptChanges() {
    let rows = this.rows.filter((row) => {
      return row.rowState == RowState.added || row.rowState == RowState.modified;
    });
    rows.map((row) => {
      row.rowState = RowState.unchanged;
    });
  }

  primaryKeys: PxDataColumn[] = [];

  _pxData: PxData;

  get pxData() {
    if (this._pxData == undefined) this._pxData = PxData.default;
    return this._pxData;
  }
  set pxData(val) {
    this._pxData = val;
  }

  constructor() {}

  toJson(): any[] {
    let json: any[] = [];

    this.rows.forEach((row) => {
      let item = {};
      this.columns.forEach((col) => {
        item[col.name] = row.getFieldValue(col.name);
      });
      json.push(item);
    });
    return json;
  }

  // return true when success
  fillSchema(table: [DataResult?]): boolean {
    this.columns = [];

    table.forEach((row) => {
      if (row) {
        if (<boolean>row["is_hidden"]) return; // return = continue in foreach

        if (row["error_message"] != null) {
          let e = new SqlDataResponse();
          e.errorMessage = <string>row["error_message"];
          e.showError();
          return false;
        }

        let name = <string>row["name"];
        let dataType: PxSqlDataType = <PxSqlDataType>row["system_type_id"];
        let isNullable = <boolean>row["is_nullable"];
        let maxLength = <number>row["max_length"];
        let precision = <number>row["precision"];
        let scale = <number>row["scale"];

        let sourceTable = <string>row["source_table"];
        let sourceColumn = <string>row["source_column"];

        let isBoundingColumn = this.tableName && sourceTable && sourceTable.toLowerCase() == this.tableName.toLowerCase();

        let isIdentityColumn = <boolean>row["is_identity_column"];
        let isPartOfUniqueKey = <boolean>row["is_part_of_unique_key"];
        let isUpdateable = <boolean>row["is_updateable"];
        let isComputedColumn = <boolean>row["is_computed_column"];

        let column = new PxDataColumn(name, dataType, isNullable, maxLength, precision, scale, sourceTable, sourceColumn, isBoundingColumn as boolean, isIdentityColumn, isPartOfUniqueKey, isUpdateable, isComputedColumn);

        this.columns.push(column);

        if (column.isPartOfUniqueKey) {
          if (column.sourceTable.toUpperCase() == this.tableName.toUpperCase()) {
            this.primaryKeys.push(column);
          }
        }
      }
    });
    return true;
  }

  fillData(table: [DataResult]) {
    this.rows = [];
    let n = Object.keys(table).length;

    table.forEach((row) => {
      this.rows.push(new PxDataRow(this, row));
    });
  }

  query(sql: string, tableName: string, fillSchema: boolean, showProgress: boolean = false, showError: boolean = true): Promise<PxDataTable> {
    // USE ON SAVE DB
    this.tableName = tableName;
    let command = sql + " SELECT * FROM sys.dm_exec_describe_first_result_set('" + sql.replace(new RegExp("'", "g"), "''") + "', NULL, 1) ";

    // if (tableName == 'POS_PI') {
    //   console.log(command)
    // }

    return new Promise((resolve, reject) => {
      this.pxData
        .getDataSet(command, showError)
        .then((res) => {
          let tableData = res.dataSet["Table"];
          let tableSchema = res.dataSet["Table1"];

          this.fillData(tableData);

          if (fillSchema) {
            let success = this.fillSchema(tableSchema);
            if (!success) {
              reject("fillSchema Error!");
              return;
            }
          }

          resolve(this);
        })
        .catch((errMsg) => {
          reject(errMsg);
        });
    });
  }

  getNewId(fieldName: string = "Id"): number {
    if (this.rows.length > 0) {
      let id = Math.max.apply(
        null,
        this.rows.map(function (row) {
          return row.getFieldValue(fieldName);
        })
      );
      return id + 1;
    } else {
      return 1;
    }
  }

  newRow(addToTable: boolean = true): PxDataRow {
    let data: DataResult[] = [];

    this.columns.forEach((column) => {
      data[column.name] = column.defaultValue != undefined ? column.defaultValue : null;
    });

    let row = new PxDataRow(this, data);
    row.rowState = RowState.detached;

    if (addToTable) this.addRow(row);

    return row;
  }

  addRow(row: PxDataRow) {
    this.rows.push(row);
    row.rowState = RowState.added;
  }

  insertRowAt(index: number, row: PxDataRow) {
    this.rows.splice(index, 0, row);
    row.rowState = RowState.added;
  }

  removeRow(row: PxDataRow) {
    let index = this.rows.findIndex((r) => {
      return r.uId == row.uId;
    });
    this.rows.splice(index, 1);
  }

  saveDB(showProgress: boolean = false, showError: boolean = true, withCreateUpdateDt: boolean = false): Promise<void> {
    let sql = this.sqlScriptForSaveDB(withCreateUpdateDt);
    return new Promise((resolve, reject) => {
      if (sql.trim() == "") {
        resolve();
        return;
      }

      // เนื่องจากมีการอ่านค่า Id ที่รันนิ่งขึ้นมาอัพเดทใน Table ด้วย จึงต้องใช้ getDataSet
      this.pxData
        .getDataSet(sql, showError)
        .then((res) => {
          if (res.tables.length) {
            // แสดงว่ามีการ select id of new row back to update ไม่งั้นจะไม่มี Table กลับมา
            let _table = res.tables[0];
            let insertRows = this.rows.filter((row) => {
              return row.rowState == RowState.added;
            });

            if (_table.length == insertRows.length) {
              // Make sure ต้องสอง Table จำนวน row ต้องเท่ากัน
              for (let i = 0; i < insertRows.length; i++) {
                let row = insertRows[i];
                let _row = _table[i];
                row.setFieldValue("Id", _row[this.tableName]);
              }
            }
          } // เรียบร้อย อัพเดท  @MAS_MP$Id = 101000001 เรียบร้อย

          this.acceptChanges();
          resolve();
        })
        .catch((errMsg) => {
          reject(errMsg);
        });

      // this.pxData.execNonQuery(sql, showError).then(res => {

      //   // UPDATE CHANGE FLAG STATUS OF TABLE AND ROWS ON SUCCESSED SAVE
      //   this.rows.forEach(row => {
      //     row.rowState = RowState.unchanged
      //   })

      //   resolve()

      // }).catch(errMsg => { reject(errMsg) })
    });
  }

  sqlScriptForColumnStructure(withPrimaryKey: boolean): string {
    let sql: string = "";
    let fields: any[] = [];
    this.columns.forEach((column) => {
      let str = `[${column.name}]  ${PxSqlDataType[column.dataType]} `;

      if (column.dataType == PxSqlDataType.varchar) str += `(${column.maxLength == -1 ? "max" : column.maxLength})`;
      else if (column.dataType == PxSqlDataType.decimal) str += `(${column.precision},${column.scale})`;

      fields.push(str);
    });
    sql = fields.join(",\n");

    if (withPrimaryKey && this.primaryKeys.length) {
      sql += `, CONSTRAINT [PK_${this.tableName}] PRIMARY KEY CLUSTERED (`;
      let pks: any[] = [];
      this.primaryKeys.forEach((pk) => {
        pks.push(`[${pk.name}]`);
      });
      sql += pks.join(",") + ")\n";
    }

    return sql;
  }

  sqlScriptForSaveDB(withCreateUpdateDt: boolean = false): string {
    let sql = "";
    let sqlNewId = "";
    let sqlInsert = "";
    let sqlSelect = "";
    let sqlUpdate = "";
    let sqlDelete = "";

    // DELETE ต้องเอา DELETE ขึ้นก่อน INSERT
    let deleteRows = this.rows.filter((row) => {
      return row.rowState == RowState.deleted;
    });

    if (deleteRows.length) {
      deleteRows.forEach((row) => {
        if (sqlDelete == "") {
          sqlDelete = PxDataTable.sqlDeleteTemplate(this);
        }
        sql += PxDataTable.sqlFillValueFromTemplate(sqlDelete, row);
      });
    }

    // PREPARE RUN ID WHEN INSERT NEW ROW
    let columnId = this.columnByName("Id");
    if (columnId != undefined) {
      let value = `@${this.tableName}$Id`;
      if (this.rows.find((row) => row.getFieldValue("Id") == value)) {
        let intSize = columnId.dataType == PxSqlDataType.bigint ? 64 : 32;

        //xx let branchID = store.getState().login.branchID;
        let branchID = 100;

        sqlNewId = PxSQL.sqlForNewId(this.tableName, branchID, intSize);
      }
    }

    // INSERT(...) SELECT ... UNION SELECT ...

    let insertRows = this.rows.filter((row) => {
      return row.rowState == RowState.added;
    });

    if (insertRows.length) {
      let insertSelect = PxDataTable.sqlScriptInsertSelectTemplate(this, withCreateUpdateDt);
      sqlInsert = insertSelect[0]; // .insert
      sqlSelect = insertSelect[1]; // .select

      let lines: any[] = [];
      let runId = 0;
      let tokenId = `@${this.tableName}$Id`;
      insertRows.forEach((row, index) => {
        if (index == 0) {
          sql += sqlNewId + sqlInsert;
        }
        lines.push(PxDataTable.sqlFillValueFromTemplate(sqlSelect, row).replace(tokenId, tokenId + "+" + runId++));
      });
      sql += lines.join(" UNION \n") + "\n";

      // For read back new Id value and then update to dataTable
      if (sqlNewId != "") {
        runId = 0;
        lines = [];
        insertRows.forEach((row) => {
          lines.push(`SELECT ${tokenId}+${runId++} AS ${this.tableName}`);
        });
        sql += lines.join(" UNION ");
      }
      //
    }

    // UPDATE
    let updateRows = this.rows.filter((row) => {
      return row.rowState == RowState.modified;
    });
    if (updateRows.length) {
      updateRows.forEach((row) => {
        if (sqlUpdate == "") {
          sqlUpdate = PxDataTable.sqlScriptUpdateTemplate(this, withCreateUpdateDt);
        }

        sql += PxDataTable.sqlFillValueFromTemplate(sqlUpdate, row);
      });
    }

    return sql;
  }

  static getSaveColumns(table: PxDataTable, withCreateUpdateDt: boolean): PxDataColumn[] {
    if (withCreateUpdateDt) {
      return table.columns.filter((col) => {
        return col.sourceTable && col.sourceTable.toUpperCase() == table.tableName.toUpperCase() && col.isComputedColumn == false && col.isIdentityColumn == false && col.isUpdateable == true;
      });
    } else {
      return table.columns.filter((col) => {
        return col.sourceTable && col.sourceTable.toUpperCase() == table.tableName.toUpperCase() && col.isComputedColumn == false && col.isIdentityColumn == false && col.isUpdateable == true && col.name != "CreateDt" && col.name != "UpdateDt" && col.sourceColumn != "UpdateDt";
      });
    }
  }

  static sqlPrimaryKeyWhereClauseTemplate(table: PxDataTable, alias: string = ""): string {
    let wherePrimaryKeys = "";
    for (let i = 0; i < table.primaryKeys.length; i++) {
      wherePrimaryKeys += (alias != "" ? alias + "." : "") + table.primaryKeys[i].name + " =  {" + table.primaryKeys[i].name + "} ";
      if (i < table.primaryKeys.length - 1) {
        wherePrimaryKeys += " AND ";
      }
    }
    if (wherePrimaryKeys != "") {
      wherePrimaryKeys = "( " + wherePrimaryKeys + " )";
    }

    return wherePrimaryKeys;
  }

  // INSERT (...) VALUES(...)

  static sqlScriptInsertValuesTemplate(table: PxDataTable, withCreateUpdateDt: boolean = false): string {
    let saveColumns = PxDataTable.getSaveColumns(table, withCreateUpdateDt);
    let fieldList = "";
    let valueList = "";

    saveColumns.forEach((column) => {
      fieldList += (fieldList == "" ? "" : ",") + column.name;
      valueList += (valueList == "" ? "" : ",") + "{" + column.name + "}"; // IF data == NSNull.Value   then = column.defaultValue อนาคต
    });

    return ` INSERT ${table.tableName}(${fieldList}) VALUES(${valueList}) `;
  }

  // INSERT (...) SELECT ... UNION SELECT ...
  // return tuple [insert,select]
  static sqlScriptInsertSelectTemplate(table: PxDataTable, withCreateUpdateDt: boolean = false): [string, string] {
    let saveColumns = PxDataTable.getSaveColumns(table, withCreateUpdateDt);
    let fieldList = "";
    let selectList = "";

    saveColumns.forEach((column) => {
      fieldList += (fieldList == "" ? "" : ",") + column.name;
      selectList += (selectList == "" ? "" : ",") + "{" + column.name + "}";
    });

    return [` INSERT ${table.tableName}(${fieldList})`, ` SELECT ${selectList} `];
    // return [' INSERT ' + table.tableName + '(' + fieldList + ' )', '  SELECT ' + selectList]
  }

  static sqlScriptUpdateTemplate(table: PxDataTable, withCreateUpdateDt: boolean = false): string {
    let saveColumns = PxDataTable.getSaveColumns(table, withCreateUpdateDt);

    var valueList = "";

    saveColumns.forEach((column) => {
      valueList += (valueList == "" ? "" : ",") + column.name + " = {" + column.name + "}";
    });

    return ` UPDATE ${table.tableName} SET ${valueList} WHERE ${PxDataTable.sqlPrimaryKeyWhereClauseTemplate(table)} `;
  }

  static sqlDeleteTemplate(table: PxDataTable): string {
    return ` DELETE ${table.tableName} WHERE ${PxDataTable.sqlPrimaryKeyWhereClauseTemplate(table)} `;
  }

  static sqlFillValueFromTemplate(template: string, row: PxDataRow): string {
    let cmd = template;

    let data = row.rowState == RowState.deleted && row.getOriginalData() != undefined ? row.getOriginalData() : row.getData();

    row.table.columns.forEach((column) => {
      let re = new RegExp("{" + column.name + "}", "g");

      // console.log('column:' + column.name + '   type:' + column.dataType);

      if (column.name == "Logo" || column.name == "Image" || column.name == "Picture") {
        cmd = cmd.replace(re, PxSQL.toVarBinaryMax(data[column.name]));
      } else {
        if (column.dataType == PxSqlDataType.date) cmd = cmd.replace(re, PxSQL.toSqlDate(data[column.name]));
        else if (column.dataType == PxSqlDataType.time) cmd = cmd.replace(re, PxSQL.toSqlTime(data[column.name]));
        else if (column.dataType == PxSqlDataType.datetime) cmd = cmd.replace(re, PxSQL.toSqlDateTime(data[column.name]));
        else cmd = cmd.replace(re, PxSQL.toValues(data[column.name]));
      }
    });

    return cmd;
  }
}

export class PxDataRow {
  uId = 0;

  attach: any; // USE LIKE TAG IN DELPHI

  table: PxDataTable;

  onRowStateChanged: OnRowStateChanged;

  _rowState = RowState.unchanged;

  get rowState() {
    return this._rowState;
  }
  set rowState(val) {
    this._rowState = val;
    if (this.onRowStateChanged) {
      this.onRowStateChanged(val);
    }
    if (this.table.onTableStateChanged) {
      this.table.onTableStateChanged();
    }
  }

  setAdded() {
    this.rowState = RowState.added;
  }

  setModified() {
    this.rowState = RowState.modified;
  }

  setChanged() {
    if (this.rowState == RowState.unchanged) this.setModified();
  }

  acceptChanges() {
    if (this.rowState == RowState.added || this.rowState == RowState.modified) this.rowState = RowState.unchanged;
  }

  get index() {
    return this.table.rows.findIndex((r) => {
      return r.uId == this.uId;
    });
  }

  // private originalData: DataResult[];
  private originalData: IData;

  // private data: DataResult[];
  private data: IData;

  getOriginalData() {
    return this.originalData;
  }

  getData() {
    return this.data;
  }
  setData(value) {
    this.data = value;
  }

  getFieldValue(name: string) {
    return this.data[name];
  }

  setFieldValues(...params: any[]) {
    if (!this.originalData) {
      this.originalData = this.data;
    }

    for (let i = 0; i < params.length; i++) {
      this.data[this.table.columns[i].name] = params[i];
    }
  }

  /** object like this : {Barcode: "8994993003174", Price: "125.", Qty: "86."}  */
  setFieldValuesByObject(object) {
    if (!this.originalData) {
      this.originalData = this.data;
    }

    let objectKeys = Object.keys(object);
    for (let i = 0; i < objectKeys.length; i++) {
      let fieldName = objectKeys[i].trim();
      let column = this.table.columnByName(fieldName);
      let value: any = null;

      if (column.isNumeric) value = Number(object[fieldName]);
      else if (column.isString) value = <string>object[fieldName];
      else if (column.isDateTime) value = new Date(object[fieldName]);
      else if (column.isBoolean) value = <boolean>object[fieldName];

      this.data[fieldName] = value;
    }
  }

  setFieldValue(name: string, value: any) {
    if (!this.originalData) {
      this.originalData = this.data;
    }

    this.data[name] = value;

    // CHANG TO .modify ONLY FROM .unchanged
    if (this.rowState == RowState.unchanged) {
      this.rowState = RowState.modified;
    }
  }

  constructor(table: PxDataTable, data: DataResult[]) {
    this.uId = PxUniqueId.generateUid();

    this.table = table;
    this.data = data;
    this.rowState = RowState.unchanged;
  }

  copy(from: PxDataRow, ...excludeColumns: string[]) {
    let e = excludeColumns;

    let a = Object.keys(this.data);
    let b = Object.keys(from.data);

    let c = a.filter((x) => b.includes(x)).filter((x) => !e.includes(x));

    c.forEach((name) => {
      this.data[name] = from.data[name];
    });

    if (this.rowState != RowState.added) this.setModified();
  }

  //   refreshData(completion:@escaping CompletionError) {

  //     var sqlUpdate = ""
  //     let whereAdded = PxDataTable.sqlPrimaryKeyWhereClauseTemplate(table: table, alias: table.sqlTableAlias!)
  //     if table.sql!.contains("WHERE") {
  //       sqlUpdate = table.sql!.replacingOccurrences(of: "WHERE", with: "WHERE \(whereAdded) AND ")
  //     } else {
  //       sqlUpdate = table.sql! + " WHERE \(whereAdded)"
  //     }

  //     let sql = PxDataTable.sqlFillValueFromTemplate(template: sqlUpdate, row: self)

  //     PxData().query(sql, alertOnError: true, showProgress: true) {
  //       (data, error) in if error != nil { completion(error); print(sql); return }

  //       guard let dataset = data!["DataSet"] as ?DataResult,
  //         let table = dataset["Table"] as ?[DataResult] else { completion(error); return
  //     }

  //     this.data = table.first!
  //     completion(nil)
  //   }

  // }

  saveDB(showProgress: boolean = false, showError: boolean = true, withCreateUpdateDt: boolean = false): Promise<any> {
    let sql = this.sqlScriptForSaveDB(withCreateUpdateDt);

    return new Promise((resolve, reject) => {
      this.table.pxData
        // .execNonQuery(sql, showError)
        .getDataSet(sql)
        .then((res) => {
          this.acceptChanges();
          // console.log(sql);

          try {
            // กรณี insert new row มันจะ return row ที่ได้ id ใหม่มาให้ด้วย นอกนั้นจะไม่มีข้อมูลกลับมา  // ฉะนั้นต้องเช็คก่อน dataSet ก่อน return
            const table = res.tables[0][0];
            resolve(table);
          } catch {
            resolve(null);
          }
        })
        .catch((errMsg) => {
          reject(errMsg);
        });
    });
  }

  silentSave(): Promise<void> {
    let sql = this.sqlScriptForSaveDB();

    return new Promise((resolve, reject) => {
      if (sql.trim() == "") resolve();
      else {
        PxData.default
          .execNonQuery(sql)
          .then(() => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  }

  sqlScriptForSaveDB(withCreateUpdateDt: boolean = false): string {
    let sql = "";
    let sqlNewId = "";
    let sqlInsert = "";
    let sqlUpdate = "";

    // PREPARE RUN ID WHEN INSERT NEW ROW
    let columnId = this.table.columnByName("Id");
    if (columnId != undefined) {
      let value = `@${this.table.tableName}$Id`;
      if (this.table.rows.find((row) => row.getFieldValue("Id") == value)) {
        let intSize = columnId.dataType == PxSqlDataType.bigint ? 64 : 32;
        //xx
        // let branchID = store.getState().login.branchID;
        let branchID = 100;
        sqlNewId = PxSQL.sqlForNewId(this.table.tableName, branchID, intSize);
      }
    }

    // INSERT
    if (this.rowState == RowState.added) {
      if (sqlInsert == "") {
        sqlInsert = sqlNewId + PxDataTable.sqlScriptInsertValuesTemplate(this.table, withCreateUpdateDt);
        /** อันนี้มาเพื่มทีหลัง ไม่รุ้จะมีปัญหาไหม เพราะอยากได้ Id ที่สร้างใหม่คืนมาด้วย */
        if (sqlNewId != "") sqlInsert += ` SELECT * FROM ${this.table.tableName} WHERE Id = @${this.table.tableName}$Id `;
      }

      sql += PxDataTable.sqlFillValueFromTemplate(sqlInsert, this);
    }

    // UPDATE
    if (this.rowState == RowState.modified) {
      if (sqlUpdate == "") {
        sqlUpdate = PxDataTable.sqlScriptUpdateTemplate(this.table, withCreateUpdateDt);
      }

      sql += PxDataTable.sqlFillValueFromTemplate(sqlUpdate, this);
    }

    return sql;
  }

  sqlScriptForDeleteDB(): string {
    let sqlDelete = "";

    sqlDelete = PxDataTable.sqlDeleteTemplate(this.table);

    return PxDataTable.sqlFillValueFromTemplate(sqlDelete, this);
  }

  deleteWithSaveDB(): Promise<void> {
    let sql = this.sqlScriptForDeleteDB();

    return new Promise((resolve, reject) => {
      this.table.pxData
        .execNonQuery(sql)
        .then((res) => {
          // REMOVE ROW FROM TABLE
          this.table.rows.splice(this.index, 1);
          resolve();
        })
        .catch((errMsg) => {
          reject(errMsg);
        });
    });
  }
}
