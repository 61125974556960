// import AsyncStorage from '@react-native-async-storage/async-storage';
import {Subject} from 'rxjs';

import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import th from 'src/i18n/th.json';
import en from 'src/i18n/en.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

i18n.use(initReactI18next).init({
  lng: 'th',
  fallbackLng: 'th',
  compatibilityJSON: 'v3',
  returnNull: false,
  resources: {
    th: th,
    en: en,
  },
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

/**
 * get current language use:
 * i18n.language
 *
 * change language use:
 * i18n.changeLanguage('th') | i18n.changeLanguage('en')
 *
 *
 */

/**
 * กรณีใน Components ให้ใช้ cont {t} = useTranslation() เท่านั้น
 * ตัวอย่างใน Components
 *
 * cont {t} = useTranslation()
 *
 *    t('TE') + 'BHName'
 *    t('Key')
 *
 *  */

/**
 * อันนี้เขียนเพิ่มเอง เพื่อเอามาใช้ในฝั่ง class library ห้ามใช้ใน Components เพราะมันจะไม่อัพเดทเวลาเปลี่ยนภาษา
 */

export const loadSavedLanguage = async () => {
  try {
    //xx
    // const value = await AsyncStorage.getItem('@language');
    i18n.changeLanguage('th');
  } catch (error) {
    console.log(error);
    i18n.changeLanguage('th');
  }
};
 

// for subscribe language changed
export const languageChanged = new Subject();

// switch and save to storage
export const switchLanguage = async (lng:string) => {
  
  i18n.changeLanguage(lng);
  try {
    // await AsyncStorage.setItem('@language', lng.toString()); // toString() to force in js side    
    languageChanged.next(lng);
  } catch (error) {
    console.log(error);
  }
};

export const translate = key => {
  switch (i18n.language) {
    case 'th':
      return th.translation[key];
      break;
    case 'en':
      return en.translation[key];
      break;
    default:
      return th.translation['th'];
  }
};

export const getText = (th:string, en:string) => {
   
  switch (i18n.language) {
    case 'th':
      return th; 
    case 'en':
      return en; 
    default:
      return th;
  }
};

// จบ //

export default i18n;
