import "src/login/Login.css";

import moment from "moment";

import { PxData } from "src/library/data-api";
import "src/utils/extensions";
import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next";
import axios from "axios";
import pako from "pako";

import { API_URL_HOST } from "src/globals/constants";
import { Navigate, useNavigate } from "react-router-dom";

import { PxConvert, PxUniqueId, PxUtils, alertMessage } from "src/utils/utils";

interface IErrorMsg {
  name: string;
  message: string;
}

export default function LoginScreen({}) {
  // const navigate = useNavigate();

  const _dbid = localStorage.getItem("dbid");
  const _user = localStorage.getItem("user");

  const [errorMessages, setErrorMessages] = useState<IErrorMsg>({ name: null, message: null });
  const [isSuccessLogin, setIsSuccessLogin] = useState(false);

  const handleSubmit = (event) => {
    // Prevent page reload
    event.preventDefault();

    var { dbid, uname, pass } = document.forms[0];

    var password = PxConvert.arrayBufferToBase64(pako.gzip(pass.value, null));

    const url = `${API_URL_HOST}/Login/${dbid.value}/${uname.value}/${password}`;

    axios
      .get(url, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json; charset=utf-8",
        },
      })
      .then((response) => {
        const res = response.data;

        let name = "";
        if (res["code"] == 101) name = "dbid";
        else if (res["code"] == 102) name = "uname";
        else if (res["code"] == 103) name = "pass";
        // success
        else if (res["code"] == 200) {
          localStorage.setItem("dbid", dbid.value);
          localStorage.setItem("user", uname.value);
          localStorage.setItem("expired", moment().add(14, "day").format("YYYY-MM-DD"));
          setIsSuccessLogin(true);
          window.location.reload();
        }

        setErrorMessages({ name: name, message: res["message"] });
      });
  };

  const renderErrorMessage = (name) => name === errorMessages.name && <div className="error">{errorMessages.message}</div>;

  // *render* \\

  return (
    <div style={{ margin: "auto", alignItems: "center", justifyContent: "center" }}>
      <div id="loginform">
        <h2 id="headerTitle">Login</h2>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <label>Database ID</label>
            <input placeholder="" name="dbid" defaultValue={_dbid} required type="text" style={{ textTransform: "uppercase" }} />
            {renderErrorMessage("dbid")}
          </div>
          <div className="row">
            <label>Username</label>
            <input placeholder="" name="uname" defaultValue={_user} required type="text" />
            {renderErrorMessage("uname")}
          </div>
          <div className="row">
            <label>Password</label>
            <input placeholder="" name="pass" required type="password" />
            {renderErrorMessage("pass")}
          </div>

          <div id="button" className="row">
            <button>Login</button>
          </div>
        </form>
      </div>
    </div>
  );
}
