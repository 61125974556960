import { Inventory } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

import { useNavigate } from 'react-router-dom';

export default function InventoryIcon() { 
  const navigate = useNavigate();
  const handleOnClick = () => navigate('/inventory');

  return (
    <IconButton onClick={handleOnClick}>
      <Inventory color="primary" sx={{ fontSize: 30 }} />
    </IconButton>
  );
}
