import "./App.css";
import DashboardScreen from "src/dashboard/Dashboard";
import InventoryScreen from "src/inventory/Inventory";

import { Link, Outlet, RouterProvider, createBrowserRouter, useNavigate } from "react-router-dom";
import InventoryIcon from "src/components/InventoryIcon";
import UserMenu from "src/components/UserMenu";
import DashboardIcon from "src/components/DashboardIcon";
import LoginScreen from "src/login/Login";
import moment from "moment";

const routes = [
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <DashboardScreen />,
      },
      {
        path: "login",
        element: <LoginScreen />,
      },
      {
        path: "dashboard",
        element: <DashboardScreen />,
      },
      {
        path: "inventory",
        element: <InventoryScreen />,
      },
    ],
  },
];

const router = createBrowserRouter(routes, { basename: "/" });

function Layout() {
  const dbID = localStorage.getItem("dbid");
  return (
    <>
      <div className="sticky" style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
        <div>
          <Link to="/" style={{ paddingLeft: 28, textDecoration: "none", fontWeight: "bold" }}>
            {dbID}
          </Link>
        </div>
        <div style={{ display: "flex", flexDirection: "row", marginRight: 28 }}>
          <DashboardIcon />
          <InventoryIcon />
          <UserMenu />
        </div>
      </div>

      <div className="content">
        <Outlet />
      </div>
    </>
  );
}

function App() {
 
  const loginExpireDate = localStorage.getItem("expired");

  if (!loginExpireDate)  return <LoginScreen />;
  if (moment(loginExpireDate, "YYYY-MM-DD") < moment())  return <LoginScreen />;

  return <RouterProvider router={router} />;
}

export default App;
